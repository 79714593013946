import React, { useState } from "react";
import iconLogin from "../../img/icons/icon-login-admin.png";

const FormLogin = ({submitLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function dataLogin(e) {
    e.preventDefault();

    const body ={
      email,
      password,
    };

    submitLogin(body);
    e.target.reset()
  }

  return (
    <div className="form--login">
      <div className="form--login__container">
        <form onSubmit={(e) => dataLogin(e)}>
          <label htmlFor="emailAdmin">Correo electrónico</label>
          <input onChange={(e)=>setEmail(e.target.value)} type="email" id="emailAdmin" />
          <label htmlFor="current-password">Contraseña</label>
          <input onChange={(e)=>setPassword(e.target.value)} type="password" id="current-password" />
          <button type="submit">
            Iniciar sesion <img src={iconLogin} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormLogin;
