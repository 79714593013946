import validateToken from "./validateToken";

const url = process.env.REACT_APP_URL_API + "login/token";

const validateTokenStorage = async () => {
  const tokenLocalStorage = localStorage.getItem("user-token");
  let vToken;
  if (tokenLocalStorage) {
    vToken = await validateToken(url, "POST", tokenLocalStorage);
  } else {
    return false;
  }
  if (vToken) {
    return tokenLocalStorage;
  } else {
    return false;
  }
};

export default validateTokenStorage;
