import React from "react";

const CopyrightSite = ({ color }) => {
  return (
    <div className="copy--site" style={color}>
      <p>Copyright © 2022 Tenis Cúcuta</p>
    </div>
  );
};

export default CopyrightSite;
