import React, { useEffect, useState } from "react";
import BtnClose from "../atoms/BtnClose";
import logo from "../../img/logo.png";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
// import img from '../../img/bgs/logo.png'

const PagePDFfactura = ({navBar, setNavBar, facturaPDF, pedido }) => {
  const [guia, setGuia] = useState();
  if (facturaPDF && pedido) {
    return (
      <div className="pdf--guia">
        <BtnClose navBar={navBar} setNavBar={setNavBar} />
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={{ paddingTop: "1cm", backgroundColor: '#eee' }}>
              <View
                style={{
                  color: "#000",
                  fontSize: "12px",
                  margin: "0 1cm 1cm 1cm",
                }}
              >
                <Image
                    src={logo}
                    style={{
                      width: "80px",
                      height: '80px',
                      maxWidth: "80px",
                      maxHeight: "80px",
                      textAlign: 'center',
                      margin: '10px auto 0'
                    }}/>
                    <Text style={{margin: '5px auto 20px'}}>www.teniscucuta.com</Text>
                <Text>
                  <Text>NUMERO DE PEDIDO: </Text>
                  <Text>{facturaPDF.id}</Text>
                </Text>
                <Text>
                  <Text>FECHA: </Text>
                  <Text>{facturaPDF.updatedAt.split('T')[0]}</Text>
                </Text>
 
                <Text>
                  <Text>NOMBRE: </Text>
                  <Text>{facturaPDF.nombre}</Text>
                </Text>
                <Text>
                  <Text>CEDULA: </Text>
                  <Text>{facturaPDF.cedula}</Text>
                </Text>
                <Text>
                  <Text>TELEFONO: </Text>
                  <Text>{facturaPDF.telefono}</Text>
                </Text>
                <Text>
                  <Text>DIRECCION: </Text>
                  <Text>{`${facturaPDF.departamento} ${facturaPDF.ciudad} ${facturaPDF.direccion}`}</Text>
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#eee",
                  border: "none",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                <Table>
                  <TableHeader>
                    <TableCell style={{fontSize: '10px',textAlign: 'center',padding: '2px'}}  weighting={0.5}>MODELO</TableCell>
                    <TableCell style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} weighting={0.2}>CANTIDAD</TableCell>
                    <TableCell style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} weighting={0.2}>TALLA</TableCell>
                    <TableCell style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} weighting={0.2}>PRECIO UND</TableCell>
                    <TableCell style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} weighting={0.2}>PRECIO TOTAL</TableCell>
                  </TableHeader>
                </Table>
                {pedido.map((e, i) => {
                  return (
                    <Table
                      key={i}
                      data={[
                        {
                          nombre: e[0].nombre,
                          cantidad: e[0].cantidad,
                          talla: e[0].talla,
                          precio: e[0].precio,
                          valor: e[0].precio / e[0].cantidad,
                        },
                      ]}
                    >
                      <TableBody>
                        <DataTableCell  weighting={0.5} style={{fontSize: '10px',textAlign: 'left',padding: '2px'}} getContent={(r) => r.nombre} />
                        <DataTableCell  weighting={0.2} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} getContent={(r) => r.cantidad} />
                        <DataTableCell  weighting={0.2} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} getContent={(r) => r.talla} />
                        <DataTableCell  weighting={0.2} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} getContent={(r) => r.valor} />
                        <DataTableCell  weighting={0.2} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}} getContent={(r) => r.precio} />
                      </TableBody>
                    </Table>
                  );
                })}
                <Table
                >
                  <TableHeader>
                    <TableCell style={{border: 'none'}} weighting={0.5}></TableCell>
                    <TableCell style={{border: 'none'}} weighting={0.2}></TableCell>
                    <TableCell style={{border: 'none'}} weighting={0.2}></TableCell>
                    <TableCell weighting={0.2} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}}>TOTAL</TableCell>
                    <TableCell weighting={0.194} style={{fontSize: '10px',textAlign: 'center',padding: '2px'}}>{facturaPDF.total}</TableCell>
                  </TableHeader>
                </Table>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
};

export default PagePDFfactura;
