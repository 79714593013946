import React, { useState } from "react";

const Search = ({ count, setCount, navBar, setConsulta }) => {
  const [busqueda, setBusqueda] = useState({});
  const rute = localStorage.getItem('navBarStatus')
  console.log(rute);
  console.log(rute === 'productos' || rute === 'productosDesactivados')
  const searchConsult = (e) => {
    e.preventDefault();
    setConsulta(busqueda);
    setCount(count + 1)
  };
  return (
    <div className="search--btn">
      <div className="search--btn__input">
        {/* <label form="site-search">Buscar en {navBar}:</label> */}
        <div className="search--btn__input--containers date">
          <label for='fechaInit'>Fecha inicial</label>
          <input onChange={(e) => setBusqueda({ ...busqueda, fechaInit: e.target.value })} type='date' name='init' id='fechaInit' />
        </div>
        <div className="search--btn__input--containers date">
          <label for='fechaEnd'>Fecha final</label>
          <input onChange={(e) => setBusqueda({ ...busqueda, fechaEnd: e.target.value })} type='date' name='end' id="fechaEnd" />
        </div>
        <div className="search--btn__input--containers productos">
          {rute === 'productos' || rute === 'productosDesactivados' ? null : <label for='SearchCategorias'>Categorias</label>}
          {rute === 'productos' || rute === 'productosDesactivados' ? null
            : <select onChange={(e) => setBusqueda({ ...busqueda, categoria: e.target.value })} id="SearchCategorias">
              <option value='' selected>seleccione</option>
              <option value='id'>N° Pedido</option>
              <option value='infopago'>Pago</option>
              <option value='createdAt'>Fecha</option>
              <option value='nombre'>Nombre</option>
              <option value='cedula'>Cédula</option>
              <option value='telefono'>Teléfono</option>
              <option value='departamento'>Departamento</option>
              <option value='ciudad'>Ciudad</option>
              <option value='direccion'>Direccion</option>
              <option value='estadopedido'>Estado</option>
              <option value='tipoenvio'>Envio</option>
            </select>}

        </div>
        <div className="search--btn__input--containers">
          <label for='site-search'>Palabra clave</label>
          <input
            onChange={(e) => setBusqueda({ ...busqueda, palabra: e.target.value })}
            type="search"
            id="site-search"
            name="q"
            placeholder="Palabra clave"
          />

        </div>
        <button className="btn__search" onClick={(e) => searchConsult(e)}>Buscar</button>
      </div>
    </div>
  );
};

export default Search;
