import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import createProductApi from "../../helpers/createProductApi";

import StockInventario from "../atoms/StockInventario";
import imgPlaceholder from "../../img/icons/product-placeholder.png";
import iconAddinventario from "../../img/icons/icon-add-inventario.png";
import iconAddProduct from "../../img/icons/icon-add-product.png";
import { useNavigate } from "react-router-dom";





const FormCreateProductEdit = ({ setToAdd, token, productEdit }) => {
  const [imgContent, setImgContent] = useState("");
  const [imgUpload, setImgUpload] = useState("");
  const [talla, setTalla] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [stock, setStock] = useState([]);

  // const url = process.env.REACT_APP_URL_API + 'producto';
  const url = `${process.env.REACT_APP_URL_API}producto/${productEdit.id}`;
  const urlFile = process.env.REACT_APP_URL_API + "files/";
  const navigate = useNavigate();
  useEffect(() => {
    // setImgUpload(productEdit.imagen);
    setImgContent(urlFile + productEdit.imagen);
    setStock([...stock, productEdit.stock.split(",")].flat(2));
  }, [productEdit]);

  const subirArchivos = (e) => {
    setImgContent(URL.createObjectURL(e[0]));
    setImgUpload(e);
  };
  

  const addInventarioStock = (e) => {
    if (talla === "" || cantidad === "") {
      swal(
        "UPPS! algo ha salido mal.",
        "talla y cantidad no pueden estar vacios",
        "info"
      );
      return;
    }
    setStock([...stock, `${talla}:${cantidad}`]);
    setTalla("");
    setCantidad("");
  };

  const enviar = async (e) => {
    e.preventDefault();

    let filesData = new FormData();
    for (let index = 1; index < 6; index++) {
      if (
        e.target[index].value !== "" &&
        typeof e.target[index].value === "string" &&
        stock !== ""
      ) {
        filesData.append(e.target[index].name, e.target[index].value);
      } else {
        swal("todos los campos son obligatorios");
        return;
      }
    }

    imgUpload
      ? filesData.append("image", imgUpload[0])
      : filesData.append("image", productEdit.imagen);

    filesData.append("stock", stock);
    const result = await createProductApi(url, "PUT", filesData, token);
    if (result) {
      e.target.reset();
      setImgContent(imgPlaceholder);
      setStock("");
      setToAdd(false)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div className="form--product">
      <div className="form--product__container">
        <form onSubmit={(e) => enviar(e)} className="form--product__form">
          <img
            className="form--product__form--image"
            src={imgContent ? imgContent : imgPlaceholder}
            alt="imgPlaceholder"
          />
          <label htmlFor="imagenProducto">Imagen producto</label>
          <input
            accept=".jpg"
            onChange={(e) => subirArchivos(e.target.files)}
            name="image"
            type="file"
            id="imagenProducto"
          />
          <label htmlFor="videoProducto">Video link</label>
          <input
            defaultValue={productEdit.video}
            name="video"
            type="text"
            id="videoProducto"
          />
          <label htmlFor="nombreProducto">Nombre producto</label>
          <input
            defaultValue={productEdit.nombre}
            name="nombre"
            type="text"
            id="nombreProducto"
          />
          <label htmlFor="precioProducto">Precio producto</label>
          <input
            defaultValue={productEdit.precio}
            name="precio"
            type="number"
            id="precioProducto"
          />
          <label htmlFor="preciointerno">Precio interno</label>
          <input defaultValue={productEdit.preciointerno} name="preciointerno" type="number" id="preciointerno" />
          <label htmlFor="categoriaProducto">Categorias Producto</label>
          <input
            defaultValue={productEdit.categorias}
            name="categorias"
            placeholder="deportivos,colegial,retro,adidas"
            type="text"
            id="categoriaProducto"
          />
          <div className="form--product__form--inventario">
            <label htmlFor="inventario">Invetario</label>
            <div className="form--product__form--inventario--stock">
              <input
                onChange={(e) => setTalla(e.target.value)}
                className="stock--talla"
                type="number"
                placeholder="talla"
                id="talla"
                value={talla ? talla : ""}
              />
              <input
                onChange={(e) => setCantidad(e.target.value)}
                className="stock--cantidad"
                type="number"
                placeholder="cantidad"
                id="cantidad"
                value={cantidad ? cantidad : ""}
              />
              <img
                onClick={(e) => addInventarioStock(e)}
                src={iconAddinventario}
                alt="icon login"
              />
            </div>
            <div className="form--product__form--inventario--view--stock">
              <div className="view--stock__title">
                <p>Tallas:</p>
                <p>Stock:</p>
              </div>
              {stock &&
                stock.map((e, i) => (
                  <StockInventario
                    x={true}
                    element={e}
                    stock={stock}
                    stockid={i}
                    key={i}
                    setStock={setStock}
                    talla={e.split(":")[0]}
                    cantidad={e.split(":")[1]}
                  />
                ))}
            </div>
          </div>
          <button type="submit">
            Actualizar producto
            <img src={iconAddProduct} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormCreateProductEdit;
