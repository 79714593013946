import swal from "sweetalert";


const validateToken = async (url, method, token) => {
  
    const req = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'x-token' : token
      },
    });
    if (req.status === 200) {
      const res = await req.json();
      return res;
    } else {
      swal("Session caducada", "debes iniciar session");
      return false
    }
  };


export default validateToken;
