import swal from "sweetalert";

const getOrders = async (url, method, token) => {
  const req = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'multipart/form-data; boundary=something',
      "x-token": token,
    },
  });
  if (req) {
    const res = await req.json();
    return res;
  } else {
    swal("UPPS! algo ha salido mal.", "verifica tus credenciales");
  }
};

export default getOrders;
