import React, { useEffect, useState } from "react";
import getConfirmacion from "../../helpers/getConfirmacion";
import iconTouch from "../../img/icons/icon-touch.png";

const FormConfirmationData = ({ token }) => {
  const url = process.env.REACT_APP_URL_API + "confirmacion";
  const urlid = process.env.REACT_APP_URL_API + "confirmacion/1";

  const [data, setdata] = useState({});

  useEffect(() => {
    const obtenerCorfirmacion = async () => {
      const c = await getConfirmacion(url, "GET");
      setdata(c.confirmacionAll[0]);
    };
    obtenerCorfirmacion();
  }, []);

  const handleSubmmit = async (e) => {
    e.preventDefault();
    const c = await getConfirmacion(urlid, "PUT", token, data);
    setdata(c.confirmacionAll[0]);
  };

  return (
    <div className="form--personal form--confirmation">
      <div className="form--personal__container">
        <form>
          <label htmlFor="contenido">Conteindo</label>
          <textarea
            onChange={(e) => setdata({ ...data, contenido: e.target.value })}
            id="contenido"
            value={data && data.contenido}
          />
          <label htmlFor="metodos">Metodos de pago</label>
          <div className="metodos">
            <label htmlFor="nequi">Nequi</label>
            <input
              onChange={(e) => setdata({ ...data, nequi: e.target.value })}
              type="text"
              id="nequi"
              value={data && data.nequi}
            />
            <label htmlFor="bancolombia">Bancolombia</label>
            <input
              onChange={(e) =>
                setdata({ ...data, bancolombia: e.target.value })
              }
              type="text"
              id="bancolombia"
              value={data && data.bancolombia}
            />
            <label htmlFor="efecty">efecty</label>
            <input
              onChange={(e) => setdata({ ...data, efecty: e.target.value })}
              type="text"
              id="efecty"
              value={data && data.efecty}
            />
            <label htmlFor="supergiros">supergiros</label>
            <input
              onChange={(e) => setdata({ ...data, supergiros: e.target.value })}
              type="text"
              id="supergiros"
              value={data && data.supergiros}
            />
          </div>

          <button onClick={(e) => handleSubmmit(e)} type="submit">
            Actualizar información
            <img src={iconTouch} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormConfirmationData;
