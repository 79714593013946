import React, { useEffect, useState } from "react";
import iconTouch from "../../img/icons/icon-touch.png";
import colombia from "../../db/Colombia.json";
import swal from "sweetalert";
import createOrder from "../../helpers/createOrder";
import { FileUploader } from "react-drag-drop-files";
import createImageGuia from "../../helpers/createImageGuia";

const FormPersonalDataEdit = ({
  editProduct,
  setOnpenShop,
  productOrder,
  token,
  updatePrice,
}) => {
  let ciudades = [];
  const [data, setdata] = useState({});
  const [selectCiudades, setSelectCiudades] = useState();
  const [infopago, setInfopago] = useState();
  const [guiaUpload, setGuiaUpload] = useState(false);
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const [imgContent, setImgContent] = useState("");
  const url = process.env.REACT_APP_URL_API + "pedido";
  const urlGuia = `${process.env.REACT_APP_URL_API}guias/`;
  let filesData = new FormData();

  const handleChange = (file) => {
    setFile(file);
    setImgContent(URL.createObjectURL(file));
    setGuiaUpload(true)
  };

  const departamento = (event) => {
    setdata({ ...data, departamento: event.target.value });
    ciudades = colombia.filter((e) => e.departamento === event.target.value);
    ciudades && setSelectCiudades(ciudades[0].ciudades);
  };

  useEffect(() => {
    editProduct.imagen && setGuiaUpload(true)
    setdata({
      nombre: editProduct.nombre,
      cedula: editProduct.cedula,
      telefono: editProduct.telefono,
      email: editProduct.email,
      direccion: editProduct.direccion,
      departamento: editProduct.departamento,
      ciudad: editProduct.ciudad,
      id: editProduct.id,
      estadopedido: editProduct.estadopedido,
      tipoenvio: editProduct.tipoenvio,
      infopago: editProduct.infopago,
      total: editProduct.total,
      productospedido: productOrder,
      empresaguia: editProduct.empresaguia,
      numeroguia: editProduct.numeroguia,
      totalinterno: editProduct.totalinterno
    });
  }, [editProduct]);

  useEffect(() => {
    infopago &&
      setdata({
        ...data,
        infopago:
          `${infopago.entidad} ${infopago.codigo} ${infopago.hora} `.trim(),
      });
  }, [infopago]);

  useEffect(() => {
    if (productOrder && data) {
      data.productospedido = productOrder;
      // data.total = Number(editProduct.total) + Number(updatePrice);
    }
  }, [productOrder]);

  const handleSubmmit = async (e) => {
    e.preventDefault();
    // if (data.estadopedido === 'Pedido enviado') {
    //  const response = await swal("ADVERTENCIA","Estás a punto de cambiar el estado del pedido a ENVIADO, si aceptas, los productos se descontarán del stock de forma irreversible.", "warning", {
    //     buttons: ['cancelar', 'aceptar'],
    //   });
    //   if (!response) {
    //     return
    //   }
    // }

    if (
      !data.departamento ||
      !data.ciudad ||
      !data.nombre ||
      !data.cedula ||
      !data.telefono ||
      !data.email ||
      !data.direccion ||
      !data.estadopedido ||
      !data.tipoenvio ||
      !data.infopago
    ) {
      swal("Todos los campos son obigatorios");
      return;
    } else {
      createOrder(`${url}/${data.id}`, "PUT", data, token);
    }

    if (file) {
      filesData.append("image", file)
      createImageGuia(`${url}/guia/${data.id}`, "PUT", filesData, token);
    }
  };

  return (
    <div className="form--personal form--personal__edit">
      <div className="form--personal__container">
        <form>
          <label htmlFor="nombreCliente">Nombre</label>
          <input
            onChange={(e) => setdata({ ...data, nombre: e.target.value })}
            type="text"
            id="nombreCliente"
            defaultValue={data.nombre}
          />

          <label htmlFor="cedulaCliente">Cédula</label>
          <input
            onChange={(e) => setdata({ ...data, cedula: e.target.value })}
            type="number"
            id="cedulaCliente"
            defaultValue={data.cedula}
          />

          <label htmlFor="teléfonoCliente">Teléfono</label>
          <input
            onChange={(e) => setdata({ ...data, telefono: e.target.value })}
            type="number"
            id="teléfonoCliente"
            defaultValue={data.telefono}
          />

          <label htmlFor="emailCliente">Correo electrónico</label>
          <input
            onChange={(e) => setdata({ ...data, email: e.target.value })}
            type="email"
            id="emailCliente"
            defaultValue={data.email}
          />

          <label htmlFor="direccionCliente">Dirección</label>
          <input
            onChange={(e) => setdata({ ...data, direccion: e.target.value })}
            type="text"
            id="direccionCliente"
            defaultValue={data.direccion}
          />

          <label htmlFor="departamento">Departamento</label>
          <select
            onChange={(e) => departamento(e)}
            name="departamento"
            id="departamento"
          // value={data.departamento}
          >
            <option defaultValue={data.departamento}>
              {data.departamento}
            </option>
            {colombia.map((e, i) => (
              <option value={e.departamento} key={i}>
                {e.departamento}
              </option>
            ))}
          </select>

          <label htmlFor="ciudad">Ciudad</label>
          <select
            onChange={(e) => setdata({ ...data, ciudad: e.target.value })}
            name="ciudad"
            id="ciudad"
          >
            <option defaultValue={data.ciudad}>{data.ciudad}</option>
            {selectCiudades &&
              selectCiudades.map((e, i) => (
                <option value={e} key={i}>
                  {e}
                </option>
              ))}
          </select>
          <label htmlFor="tipoenvio">Tipo de envio</label>
          <select
            onChange={(e) => setdata({ ...data, tipoenvio: e.target.value })}
            name="tipoenvio"
            id="tipoenvio"
          >
            <option defaultValue={data.tipoenvio}>{data.tipoenvio}</option>
            <option defaultValue="Envio al cobro">Envio al cobro</option>
            <option defaultValue="Envio pago">Envio pago</option>
            <option defaultValue="Envio contraentrega">
              Envio contraentrega
            </option>
          </select>

          <label htmlFor="estadopedido">Estado pedido</label>
          <select
            onChange={(e) => setdata({ ...data, estadopedido: e.target.value })}
            name="estadopedido"
            id="estadopedido"
          >
            <option defaultValue={data.estadopedido}>
              {data.estadopedido}
            </option>
            <option defaultValue="pedido recibido">Pedido recibido</option>
            <option defaultValue="pedido Agendado">Pedido Agendado</option>
            <option defaultValue="pago aprobado">Pago aprobado</option>
            <option defaultValue="preparando pedido">Preparando pedido</option>
            <option defaultValue="pedido enviado">Pedido enviado</option>
          </select>

          <div className="medios--pago">
            <label htmlFor="infopago">Medio de pago</label>
            <select
              onChange={(e) =>
                setInfopago({ ...infopago, entidad: e.target.value })
              }
              name="infopago"
              id="infopago"
            >
              <option defaultValue={data.infopago}>{data.infopago}</option>
              <option defaultValue="nequi">Nequi</option>
              <option defaultValue="bancolombia">Bancolombia</option>
              <option defaultValue="supergiros">Supergiros</option>
              <option defaultValue="Efecty">Efecty</option>
            </select>

            <label htmlFor="codigo">Código</label>
            <input
              onChange={(e) =>
                setInfopago({ ...infopago, codigo: e.target.value })
              }
              type="text"
              id="codigo"
            />
            <label htmlFor="hora">Hora</label>
            <input
              onChange={(e) =>
                setInfopago({ ...infopago, hora: e.target.value })
              }
              type="text"
              id="hora"
            />
          </div>

          <label htmlFor="empresa">Empresa transporte</label>
          <select
            onChange={(e) => setdata({ ...data, empresaguia: e.target.value })}
            name="empresa"
            id="empresa"
          >
            <option defaultValue={data.empresaguia}>
              {data.empresaguia}
            </option>
            <option defaultValue="interrapidisimo">Interrapidísimo</option>
            <option defaultValue="envia">Envía</option>
          </select>

          <label htmlFor="guia">N° Guia</label>
          <input
            onChange={(e) => setdata({ ...data, numeroguia: e.target.value })}
            type="text"
            id="guia"
            defaultValue={data.numeroguia}
          />
        </form>
      </div>

      <div className="btn--edit--order">
        {guiaUpload
          ?
          <div className="imgen-guia">
            <img width='100%' src={imgContent ?imgContent :urlGuia + editProduct.imagen} />
            <button onClick={() => setGuiaUpload(false)}>X</button>
          </div>
          :
          <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
        }

        <button onClick={(e) => setOnpenShop(true)} type="submit">
          Agregar producto
          <img src={iconTouch} alt="icon login" />
        </button>

        <button onClick={(e) => handleSubmmit(e)} type="submit">
          Actualizar pedido
          <img src={iconTouch} alt="icon login" />
        </button>
      </div>
    </div>
  );
};

export default FormPersonalDataEdit;
