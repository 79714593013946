import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import createOrder from "../../helpers/createOrder";
import Search from "../atoms/Search";
import Spinner from "../atoms/Spinner";
import { json, useNavigate } from "react-router-dom";
import PagePDFguia from "../pages/PagePDFguia";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PagePDFfactura from "../pages/PagePDFfactura";

const Orders = ({
  isDisable,
  facturas,
  token,
  order,
  navBar,
  adminProduct,
  setNavBar
}) => {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_URL_API + "pedido";
  const [consulta, setConsulta] = useState("");
  const [trueOrder, setTrueOrder] = useState();
  const [guiaPDF, setGuiaPDF] = useState(null);
  const [facturaPDF, setFacturaPDF] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [tipoDeEnvio, setTipoDeEnvio] = useState(null);
  const [openGuia, setOpenGuia] = useState(false);
  const [openFactura, setOpenFactura] = useState(false);
  const [count, setCount] = useState(null)
  useEffect(() => {
    setTrueOrder(order);
  }, [order]);

  const { categoria, fechaEnd, fechaInit, palabra } = consulta
  useEffect(() => {
    if ((categoria || fechaEnd || fechaInit || palabra)) {
      let arrayEnd;
      if (order) {
        if (fechaEnd && fechaInit) {
          arrayEnd = order.filter(pedido => {
            const fechaPedido = new Date(pedido.createdAt.slice(0, 10).split("-"));
            const fechainicio = new Date(fechaInit.split("-"));
            const fechafinal = new Date(fechaEnd.split("-"));
            if (fechaPedido >= fechainicio && fechaPedido <= fechafinal) {
              console.log(pedido);
              return pedido;
            }
          })

        }
        if (categoria && palabra) {
          console.log(categoria, palabra)
          arrayEnd = order.filter(pedido => pedido[categoria].toString().toLowerCase().includes(palabra.toLowerCase()))
        }
        setTrueOrder(arrayEnd)
      }
    } else {
      setTrueOrder(order);
    }
  }, [count]);

  const handleAction = async (e) => {
    e.estadopedido = "Desactivado";
    const reult = await swal("Deseas eliminar este producto", {
      buttons: ["Cancelar", "Aceptar"],
    });
    e.productospedido = await JSON.parse(e.productospedido);
    if (reult) {
      const req = await createOrder(`${url}/${e.id}`, "PUT", e, token);
      req && navigate("/pedidos");
    }
  };
  const handleActionActivate = async (e) => {
    e.estadopedido = "Pedido recibido";
    const reult = await swal("Deseas activar este producto", {
      buttons: ["Cancelar", "Aceptar"],
    });
    e.productospedido = await JSON.parse(e.productospedido);
    if (reult) {
      const req = await createOrder(`${url}/${e.id}`, "PUT", e, token);
      req && navigate("/pedidos");
    }
  };

  const handlePDFguia = async (e) => {
    const pedidosNombre = await JSON.parse(e.productospedido).map(
      (e) => `${e.nombre} ${e.talla} / ${e.cantidad}`
    );
    setTipoDeEnvio(e.tipoenvio)
    setPedido(pedidosNombre);
    setGuiaPDF(e.id);
    setOpenGuia(true);
  };

  const handlePDFactura = async (e) => {
    const pedidosNombre = await JSON.parse(e.productospedido).map((e) => [
      {
        nombre: e.nombre,
        talla: e.talla,
        cantidad: e.cantidad,
        precio: e.precio,
      },
    ]);
    const facturaID = await facturas.filter(
      (element) => element.pedidoID === e.id
    );

    setPedido(pedidosNombre);
    setFacturaPDF(e);
    setOpenFactura(true);
  };

  return (
    <div className="orders">
      {openGuia && (
        <PagePDFguia navBar={navBar} setNavBar={setNavBar} tipoDeEnvio={tipoDeEnvio} pedido={pedido} token={token} guiaPDF={guiaPDF} />
      )}
      {openFactura && (
        <PagePDFfactura navBar={navBar} setNavBar={setNavBar} pedido={pedido} facturaPDF={facturaPDF} />
      )}
      {!openGuia && !openFactura ? (
        <>
          <div>
            <Search
              count={count}
              setCount={setCount}
              navBar={navBar}
              consulta={consulta}
              setConsulta={setConsulta}
            />
          </div>
          <div className="container--table">
            <table className="orders--table">
              <tr className="table--header">
                <td>N° Pedido</td>
                <td>Fecha</td>
                <td>Nombre</td>
                <td>Cédula</td>
                <td>Teléfono</td>
                {/* <td>Correo</td> */}
                <td>Departamento</td>
                <td>Ciudad</td>
                <td>Direccion</td>
                <td>Estado</td>
                <td>Envio</td>
                <td>Total</td>
                <td>Total interno</td>
                <td>Pago</td>
                <td>Acciones</td>
              </tr>
              {trueOrder ? (
                trueOrder.length <= 0 ? (
                  <h3>No hay resultados h3ara esta busqueda...</h3>
                ) : (
                  trueOrder.map((e, i) => (
                    <tr className="table--item" key={i}>
                      <td>{e.id}</td>
                      <td>{e.createdAt.split("T")[0]}</td>
                      <td>{e.nombre}</td>
                      <td>{e.cedula}</td>
                      <td>
                        <a
                          style={{ textDecoration: 'none', fontWeight: '700' }}
                          target="_blank"
                          href={`https://wa.me/57${e.telefono}?text=Tu numero de pedido es: ${e.id}`}
                        >
                          {e.telefono}
                        </a>
                      </td>
                      {/* <td>{e.email}</td> */}
                      <td>{e.departamento}</td>
                      <td>{e.ciudad}</td>
                      <td>{e.direccion}</td>
                      <td>{e.estadopedido}</td>
                      <td>{e.tipoenvio}</td>
                      <td>{e.total}</td>
                      <td>{e.totalinterno}</td>
                      <td>{e.infopago}</td>
                      <td className="table--item__actions">
                        <button
                          className="table--item__actions--admin"
                          onClick={() => adminProduct(e)}
                        >
                          Administrar
                        </button>
                        {e.estadopedido !== "Pedido recibido" ? (
                          <button
                            className="table--item__actions--guia"
                            onClick={() => handlePDFguia(e)}
                          >
                            Guia
                          </button>
                        ) : (
                          <button
                            style={{ backgroundColor: "#aaa" }}
                            className="table--item__actions--guia"
                          // onClick={() => handlePDFguia(e)}
                          >
                            Guia
                          </button>
                        )}
                        {/* <button
                          className="table--item__actions--guia"
                          onClick={() => handlePDFguia(e)}
                        >
                          Guia
                        </button> */}
                        <button
                          onClick={() => handlePDFactura(e)}
                          className="table--item__actions--factura"
                        >
                          Factura
                        </button>
                        {isDisable ? (
                          <button
                            style={{ backgroundColor: "#43BF39" }}
                            className="table--item__actions--disable"
                            onClick={(event) => handleActionActivate(e, event)}
                          >
                            Activar
                          </button>
                        ) : (
                          <button
                            className="table--item__actions--disable"
                            onClick={(event) => handleAction(e, event)}
                          >
                            Desactivar
                          </button>
                        )}
                        {/* <button
                          className="table--item__actions--disable"
                          onClick={(event) => handleAction(e, event)}
                        >
                          Desactivar
                        </button> */}
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <Spinner />
              )}
            </table>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Orders;
