import swal from "sweetalert";


const loginApi = async (url, method, body) => {
  
    const req = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });
    if (req.status === 200) {
      const res = await req.json();
      localStorage.setItem('user-token', res.token);
      swal("Login exitoso", "puedes comenzar");
      return true;
    } else {
      swal("UPPS! algo ha salido mal.", "verifica tus credenciales");
    }
  };

export default loginApi;
