import React, { useEffect, useState } from "react";
import getRemitente from "../../helpers/getUsersRemitente";
import iconLogin from "../../img/icons/icon-login-admin.png";

const FormRemitente = ({ token }) => {
  const url = process.env.REACT_APP_URL_API + "remitente";
  const urlid = process.env.REACT_APP_URL_API + "remitente/1";
  const [remitente, setRemitente] = useState(null);
  const [newRemitente, setNetRemitente] = useState(null);

  useEffect(() => {
    const obtenerRemitente = async () => {
      const remitente = await getRemitente(url, "GET", token);
      setRemitente(remitente.remitenteAll[0]);
    };
    obtenerRemitente();
  }, []);

  const enviarRemitente = async (e) => {
    e.preventDefault();
    const updateRemitente = await getRemitente(urlid, "PUT", token, remitente);
  };

  return (
    <div className="form--remitente">
      <div className="form--remitente__container">
        <form>
          <label htmlFor="nombreRemitente">Nombre</label>
          <input
            onChange={(e) =>
              setRemitente({ ...remitente, nombre: e.target.value })
            }
            defaultValue={remitente && remitente.nombre}
            type="text"
            id="nombreRemitente"
          />
          <label htmlFor="emailRemitente">Email</label>
          <input
            onChange={(e) =>
              setRemitente({ ...remitente, email: e.target.value })
            }
            defaultValue={remitente && remitente.email}
            type="text"
            id="emailRemitente"
          />
          <label htmlFor="cedulaRemitente">Cédula</label>
          <input
            onChange={(e) =>
              setRemitente({ ...remitente, cedula: e.target.value })
            }
            value={remitente && remitente.cedula}
            type="text"
            id="cedulaRemitente"
          />
          <label htmlFor="direccion">Dirección</label>
          <input
            onChange={(e) =>
              setRemitente({ ...remitente, direccion: e.target.value })
            }
            defaultValue={remitente && remitente.direccion}
            type="text"
            id="direccion"
          />
          <label htmlFor="Teléfono">Teléfono</label>
          <input
            onChange={(e) =>
              setRemitente({ ...remitente, telefono: e.target.value })
            }
            defaultValue={remitente && remitente.telefono}
            type="text"
            id="Teléfono"
          />

          <button onClick={(e) => enviarRemitente(e)} type="submit">
            Actualizar remitente
            <img src={iconLogin} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormRemitente;
