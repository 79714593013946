import React from "react";
import iconBackBlack from "../../img/icons/icon-back-black.png";
import iconBackWhite from "../../img/icons/icon-back-white.png";

const Back = ({ placeOrder, color, setPlaceOrder }) => {
  const goToBack = () => {
    if (placeOrder === "pedido") {
      setPlaceOrder("tienda");
    } else if (placeOrder === "tienda") {
      setPlaceOrder("personal");
    } else if (placeOrder === "confirmacion") {
      setPlaceOrder("pedido");
    } else if (placeOrder === "personal") {
      setPlaceOrder("pedido");
    }
  };
  return (
    <div className="back--arrow">
      <img
        onClick={() => goToBack()}
        className="back--arrow__icon"
        src={color === "black" ? iconBackBlack : iconBackWhite}
      />
    </div>
  );
};

export default Back;
