import React from "react";
import BtnClose from "../atoms/BtnClose";
import FormCreateProduct from "../molecules/FormCreateProduct";
import FormCreateProductEdit from "../molecules/FormCreateProductEdit";

const ModalCreateProduct = ({navBar, setNavBar, setToAdd, token, productEdit }) => {
  return (
    <div className="modal--create__product">
      <BtnClose setNavBar={setNavBar} navBar={navBar} setToAdd={setToAdd}/>
      {productEdit ? (
        <FormCreateProductEdit setToAdd={setToAdd} productEdit={productEdit} token={token} />
      ) : (
        <FormCreateProduct setToAdd={setToAdd}  token={token} />
      )}
    </div>
  );
};

export default ModalCreateProduct;
