import React, { useEffect } from "react";
import swal from "sweetalert";

const CheckOrderEdit = ({
  productOrder,
  setProductOrder,
  editProduct,
  setEditProduct,
  productsNew,
}) => {


  const deleteProduct = async (uid) => {
    const reult = await swal("Deseas eliminar este producto", {
      buttons: ["Cancelar", "Aceptar"],
    });

    if (reult) {
      const productsFilter = productOrder.filter((e) => e.uid !== uid);
      setProductOrder(productsFilter);
      const totalScore = productsFilter.map((e) => e.precio);
      const totalP = totalScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const totalInternoScore = productOrder.map((e) => e.preciointerno);
      const totalI = totalInternoScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      setEditProduct({ ...editProduct, total: totalP, totalinterno: totalI });
    }
  };

  useEffect(() => {
    if (productOrder) {

      const totalScore = productOrder.map((e) => e.precio);
      const totalP = totalScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const totalInternoScore = productOrder.map((e) => e.preciointerno);
      const totalI = totalInternoScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      setEditProduct({ ...editProduct, total: totalP, totalinterno: totalI });
    }
  }, [productOrder]);

  return (
    <div className="check--order__edit">
      <div className="check--order__edit--total">
        <h2>TOTAL: $ {editProduct.total}</h2>
      </div>
      {productOrder &&
        productOrder.map((e, i) => (
          <div className="check--order__producto" key={i}>
            <div className="one">
              <img src={e.imagen} alt="productos tenis" />
            </div>
            <div className="two">
              <h2>{e.nombre}</h2>
              <div className="two__content">
                <div c>
                  <h3>Talla</h3>
                  <p>{e.talla}</p>
                </div>
                <div>
                  <h3>Cantidad</h3>
                  <p>{e.cantidad}</p>
                </div>
              </div>
            </div>
            <div className="three">
              <p>$ {e.precio}</p>
            </div>
            <div className="four">
              <button
                onClick={() => deleteProduct(e.uid)}
                className="four__delete"
              >
                Borrar
              </button>
            </div>
          </div>
        ))}
      {/* <div>
        <button className="btn--end" onClick={(e) => endOrder(e)}>Finalizar</button>
      </div> */}
    </div>
  );
};

export default CheckOrderEdit;
