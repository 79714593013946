import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import confirmationImg from "../../img/icons/confirmation_icon.png";
import iconTouch from "../../img/icons/icon-touch.png";
import iconWhatsapp from "../../img/icons/icon-whatsapp.png";
import iconCar from "../../img/icons/icon-car-white.png";
import imgNequi from "../../img/nequi.png";
import imgEfecty from "../../img/efecty.png";
import imgSupergiros from "../../img/supergiros.jpg";
import imgBancolombia from "../../img/bancolombia.jpg";
import swal from "sweetalert";

const ConfirmationInfo = ({ orderSend, data }) => {
  const [content, setContent] = useState();
  const tel = "573017049367"; 

  useEffect(() => {
    data.contenido && setContent(data.contenido.split("<br>"));
    setTimeout(() => {
      swal({
        title: `Pedido N° ${orderSend.pedido}`,
        text: '¡Pedido creado exitosamente!',
        buttons: {
          confirm: {
            text: 'Aceptar',
            value: true,
            className: 'confirmation-button-class' // Agrega aquí la clase para el botón de confirmación
          }
        }
      }).then((value) => {
        if (value) {
          window.open(`https://wa.me/${tel}?text=Este es mi pedido: ${orderSend.pedido}`, '_blank');
        }
      });
    }, 2000);
  }, [data]);
  return (
    <div className="confirmation--info ">
      <div className="confirmation--info__content">
        <div className="confirmation--id">
          <img
            className="confirmation--info__icon"
            src={confirmationImg}
            alt="Pedido recibido"
          />
          <div>
            <h2>Numero de pedido</h2>
            <h1>{orderSend.pedido}</h1>
          </div>
          <div>
            <h2>Total a pagar</h2>
            <h1>$ {orderSend.total}</h1>
          </div>
        </div>
        {content && content.map((e, i) => <p key={i}>{e}</p>)}
      </div>
      <div className="confirmation--methods">
        {data.efecty && (
          <div>
            <img src={imgEfecty} alt="efecty" />
            <p>{data.efecty}</p>
          </div>
        )}
        {data.nequi && (
          <div>
            <img src={imgNequi} alt="efecty" />
            <p>{data.nequi}</p>
          </div>
        )}
        {data.supergiros && (
          <div>
            <img src={imgSupergiros} alt="efecty" />
            <p>{data.supergiros}</p>
          </div>
        )}
        {data.bancolombia && (
          <div>
            <img src={imgBancolombia} alt="efecty" />
            <p>{data.bancolombia}</p>
          </div>
        )}
      </div>
      <div className="confirmation--info__buttons">
        <Link to={"/home"}>
          <button type="submit">
            Hacer pedido
            <img src={iconCar} alt="icon login" />
          </button>
        </Link>
        <Link to={"/order"}>
          <button type="submit">
            Consultar Pedido
            <img src={iconTouch} alt="icon login" />
          </button>
        </Link>
        <a target="_blank" href={`https://wa.me/${tel}?text=Este es mi pedido: ${orderSend.pedido}`}>
          <button type="submit">
            Whatsapp
            <img src={iconWhatsapp} alt="icon login" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default ConfirmationInfo;
