import React from "react";
import closeBtn from "../../img/icons/carbon_close-filled.png";
import { useNavigate } from "react-router-dom";

const BtnClose = ({ setToAdd, setNavBar, setClose, navBar }) => {
  const navigate = useNavigate();
  const handleClose = (bool) => {
    setClose && setClose(false)
    window.localStorage.setItem('navBarStatus', navBar)
    navigate("/pedidos");
  };

  return (
    <div className="btn--close">
      <img
        className="modal--shop__close"
        onClick={() => handleClose(false)}
        width="50px"
        src={closeBtn}
      />
    </div>
  );
};

export default BtnClose;
