/*global fbq*/
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import createOrder from "../../helpers/createOrder";
import Back from "../atoms/Back";
import { Dcontex } from "../../App";


const CheckOrder = ({
  total,
  setPreOrder,
  setOrderSend,
  setPlaceOrder,
  preorder,
  placeOrder,
  setCantidad,
  cantidad,
  setTotal,
  setProductsCar,
  productsCar,
  setTotalInterno
}) => {
  const value = useContext(Dcontex)
  const url = process.env.REACT_APP_URL_API + "pedido";
  const navigate = useNavigate();
  const endOrder = () => {
    setPlaceOrder("personal");
    // pedidoCReate(preorder);
    console.log(productsCar);
    fbq('trackCustom', 'Confirmar Pedido', {
      country_name: value.country_name,
      country_capital: value.country_capital,
      city: value.city,
      region: value.region,
      timezone: value.timezone,
      postal: value.postal,
      currency: value.currency,
      ip: value.ip,
      products: productsCar,
      total
    });
    console.log('enviando pixel de confirmar pedido');
  };
  
  let pedidoCReate = async (preorder) => {
    let orderSend = await createOrder(url, "POST", preorder);
    setOrderSend(orderSend.uniqueID);
  };
  
  const deleteProduct = (id) => {
    setPreOrder({...preorder, productospedido : preorder.productospedido.filter(e => e.uid !== id)})
    setProductsCar(productsCar.filter(e => e.uid !== id))
  }
  useEffect(()=> {
    const getTotal = async () => {
      const totalScore = await preorder.productospedido.map((e) => e.precio);
      setTotal(
        totalScore.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0 
        )
      );
      const totalScoreInterno = productsCar.map((e) => e.preciointerno);
      setTotalInterno(
        totalScoreInterno.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      );
      const totalCantidad = await preorder.productospedido.map((e) => Number(e.cantidad));
      setCantidad(
        totalCantidad.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      );
    };
    getTotal();
  }, [preorder])

  return (
    <div className="check--order">
      <Back placeOrder={placeOrder} setPlaceOrder={setPlaceOrder} />
      {preorder.productospedido.map((e, i) => (
        <div className="check--order__producto" key={i}>
          <div className="one">
            <img src={e.imagen} alt="productos tenis" />
          </div>
          <div className="two">
            <h2>{e.nombre}</h2>
            <div className="two__content">
              <div c>
                <h3>Talla</h3>
                <p>{e.talla}</p>
              </div>
              <div>
                <h3>Cantidad</h3>
                <p>{e.cantidad}</p>
              </div>
            </div>
          </div>
          <div className="three">
            <p>$ {e.precio}</p>
          </div>
          <div className="four">
            <button
              onClick={() => deleteProduct(e.uid)}
              className="four__delete"
            >
              Borrar
            </button>
          </div>
        </div>
      ))}
      <div className="check--order__footer">
        <div className="check--order__info">
          <div>
            <p>Cantidad</p>
            <p>{cantidad}</p>
          </div>
          <div>
            <p>Total</p>
            <p>${total}</p>
          </div>
        </div>
        <div className="check--order__end">
          <button onClick={() => endOrder()}>Continuar</button>
        </div>
      </div>
    </div>
  );
};

export default CheckOrder;
