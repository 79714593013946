import React, { useEffect, useState } from "react";
import FormLogin from "../molecules/FormLogin";
import SectionWhite from "../molecules/SectionWhite";
import loginApi from "../../helpers/useAPI";
import { useNavigate } from "react-router-dom";
import validateTokenStorage from "../../helpers/validateTokenStorage";
import validateTokenStorageLogin from "../../helpers/validateTokenStorageLogin";

const PageLogin = ({ setToken }) => {
  const [loginTrue, setLoginTrue] = useState('');

  // se utiliza para hacer redirecciones
  const navigate = useNavigate();

  useEffect(()=> {
    const validationLogin = async () => {
      const result = await validateTokenStorage();
     if (result) {
      navigate('/pedidos')
      window.location.reload();
    }
  }
  validationLogin();
}, [])

const submitLogin = async (body) => {
  const url = process.env.REACT_APP_URL_API + 'login';
  
  const result = await loginApi(url, "POST", body);
  if (result) {
    navigate("/pedidos");
    window.location.reload();
      // setToken(validateTokenStorageLogin());
    }
  };

  return (
    <div className="page--base">
      <div className="page--base__container">
        <div className="page--base__container--left">
          <SectionWhite />
        </div>
        <div className="page--base__container--right">
          <FormLogin submitLogin={submitLogin} />
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
