/*global fbq*/
import React, {useState, useContext } from "react";
import FormQuery from "../atoms/FormQuery";
import LogoSite from "../atoms/LogoSite";
import OrderTimeLine from "../atoms/OrderTimeLine";
import getOrders from "../../helpers/getOrders";
import swal from "sweetalert";
import { Dcontex } from "../../App";


const ModalStatusOrder = () => {
  const [consulta, setConsulta] = useState();
  const value = useContext(Dcontex)
  
  const getOrdersRaw = async (e, id) => {
    e.preventDefault();
    if (id === " " || id === undefined || !id) {
      swal("Debes ingresar un N° de pedido Valido");
    } else{
      const url = `${process.env.REACT_APP_URL_API}pedido/${id}`;
      const ordersRaw = await getOrders(url, "GET", '');
      setConsulta(ordersRaw)
      fbq('trackCustom', 'Consultar pedido', {
        order_id: id,
        country_name: value.country_name,
        country_capital: value.country_capital,
        city: value.city,
        region: value.region,
        timezone: value.timezone,
        postal: value.postal,
        currency: value.currency,
        ip: value.ip,
      });
      console.log('enviando pixel a facebook');
    }
  };
  return (
    <div className="modal--status__order">
      <div className="modal--status__order--container">
        <LogoSite />
        <OrderTimeLine consulta={consulta} />
        <FormQuery getOrdersRaw={getOrdersRaw} />
      </div>
    </div>
  );
};

export default ModalStatusOrder;
