import React from "react";
import iconUser from "../../img/icons/icon-admin.png";
import iconTouchWhite from "../../img/icons/icon-touch-white.png";

const AdminPanel = ({changeOrderNav, logout}) => {
  return (
    <div className="admin--panel">
      <div className="admin--panel__user">
        <img src={iconUser} alt="icon admin" />
        <h2>Administrador</h2>
      </div>
      <div className="admin--panel__actions">
        <button onClick={() => changeOrderNav("remitente")}>
          Remitente
          {/* <img src={iconTouchWhite} />{" "} */}
        </button>
        <button onClick={() => changeOrderNav("confirmacion")}>
          Confirmación
          {/* <img src={iconTouchWhite} />{" "} */}
        </button>
        <button onClick={() => changeOrderNav("popup")}>
          popup
          {/* <img src={iconTouchWhite} />{" "} */}
        </button>
        <button onClick={(e) => logout(e)}>
          Cerrar Sesion 
          {/* <img src={iconTouchWhite}/>{" "} */}
        </button>
      </div>
    </div>
  );
};

export default AdminPanel;
