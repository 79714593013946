import swal from "sweetalert";

const createImageGuia = async (url, method, body, token,) => {
    const req = await fetch(url, {
      method: method,
      headers: {
        "x-token": token && token,
      },
      body: body
    });
    if (req) {
      const res = await req.json();
      console.log(res.msg);
      return true;
    } else {
      swal("UPPS! algo ha salido mal.", "no se pudo enviar la guia");
    }
  };

export default createImageGuia