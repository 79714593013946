import React, { useState, useEffect } from "react";
import swal from "sweetalert";

import Productos from "../molecules/Productos";
import SectionWhiteTwo from "../molecules/SectionWhiteTwo";
import FormPersonalData from "../molecules/FormPersonalData";
import CheckOrder from "../molecules/CheckOrder";
import PageConfirmation from "./PageConfirmation";
import SectionWhitethree from "../molecules/SectionWhitethree";

const PageTienda = () => {
  const [placeOrder, setPlaceOrder] = useState("tienda");
  const [personalData, setPersonalData] = useState({});
  const [productsCar, setProductsCar] = useState([]);
  const [total, setTotal] = useState("");
  const [totalInterno, setTotalInterno] = useState("");
  const [preorder, setPreOrder] = useState("");
  const [order, setOrder] = useState("");
  const [category, setCategory] = useState("");
  const [filterCategory, setFilterCategory] = useState(null);
  const [orderSend, setOrderSend] = useState();
  const [data, setdata] = useState({});
  const [cantidad, setCantidad] = useState([]);


  const sendPreOrder = () => {
    if (productsCar.length > 0) {
      setPreOrder({
        ...personalData,
        total: total,
        totalInterno: totalInterno,
        productospedido: productsCar,
      });
      setPlaceOrder("pedido");
    } else {
      swal("debes agregar al menos un producto");
    }
  };

  useEffect(() => {
    setPreOrder({
      ...personalData,
      total: total,
      totalInterno: totalInterno,
      productospedido: preorder.productospedido,
    });
    // // preorder.productospedido && setProductsCar([...productsCar, preorder.productospedido ]);
  }, [total]);


  return (
    <div className="page--base page--tienda">
      <div className="page--base__container page--tienda__container">
        <div className="page--base__container--left page--tienda__container--left">
          {placeOrder === "personal" ||
          placeOrder === "confirmacion" ||
          placeOrder === "pedido" ? (
            <SectionWhitethree
              setFilterCategory={setFilterCategory}
              category={category}
            />
          ) : (
            <SectionWhiteTwo
              setFilterCategory={setFilterCategory}
              category={category}
            />
          )}
        </div>
        <div className="page--base__container--right page--tienda__container--right">
          {placeOrder === "personal" && (
            <FormPersonalData
              setdata={setdata}
              data={data}
              setPersonalData={setPersonalData}
              setPlaceOrder={setPlaceOrder}
              setOrderSend={setOrderSend}
              setOrder={setOrder}
              preorder={preorder}
              setPreOrder={setPreOrder}
              placeOrder={placeOrder}
            />
          )}
          {placeOrder === "tienda" && (
            <Productos
              setCantidad={setCantidad}
              cantidad={cantidad}
              placeOrder={placeOrder}
              filterCategory={filterCategory}
              personalData={personalData}
              setCategory={setCategory}
              total={total}
              setTotal={setTotal}
              setTotalInterno={setTotalInterno}
              sendPreOrder={sendPreOrder}
              productsCar={productsCar}
              setProductsCar={setProductsCar}
              setPlaceOrder={setPlaceOrder}
            />
          )}
          {placeOrder === "pedido" && (
            <CheckOrder
              productsCar={productsCar}
              setProductsCar={setProductsCar}
              setTotal={setTotal}
              setTotalInterno={setTotalInterno}
              setCantidad={setCantidad}
              cantidad={cantidad}
              total={total}
              placeOrder={placeOrder}
              setOrderSend={setOrderSend}
              order={order}
              setPlaceOrder={setPlaceOrder}
              setOrder={setOrder}
              preorder={preorder}
              setPreOrder={setPreOrder}
            />
          )}
          {placeOrder === "confirmacion" && (
            <PageConfirmation
              setPlaceOrder={setPlaceOrder}
              placeOrder={placeOrder}
              orderSend={orderSend}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PageTienda;
