import React, { useEffect, useState } from "react";
import getProducts from "../../helpers/getProducts";
import getUsers from "../../helpers/getUsers";


const Users = ({token}) => {
  const [user, setUsers] = useState();

  const url = process.env.REACT_APP_URL_API + "usuario";;
  
  useEffect(() => {
    const getUsersRaw= async (e) => {
      const usersRaw = await getUsers(url, "GET", token);
      setUsers(usersRaw.usuarios)
    };
    getUsersRaw();
  }, []);
  return (
    <div className="products--list">
      <div  className="container--table reportes">
        <table className="orders--table products--list__table">
          <tr className=" table--header products--list__table--indice">
            <td>N° </td>
            <td>Nombre</td>
            <td>Correo</td>
          </tr>
          {user
            ? user.map((e, i) => (
                <tr className="table--item products--list__table--product" key={i}>
                  <td>{e.id}</td>
                  <td>{e.nombre}</td>
                  <td>{e.email}</td>
                </tr>
              ))
            : null}
        </table>
      </div>
    </div>
  );
};

export default Users;
