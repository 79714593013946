import React, { useState } from "react";
import swal from "sweetalert";
import createProductApi from "../../helpers/createProductApi";

import StockInventario from "../atoms/StockInventario";
import imgPlaceholder from "../../img/icons/product-placeholder.png";
import iconAddinventario from "../../img/icons/icon-add-inventario.png";
import iconAddProduct from "../../img/icons/icon-add-product.png";

const FormCreateProduct = ({token, productEdit}) => {
  const [imgContent, setImgContent] = useState("");
  const [imgUpload, setImgUpload] = useState("");
  const [talla, setTalla] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [stock, setStock] = useState([]);

  const url = process.env.REACT_APP_URL_API + 'producto';

  const subirArchivos = (e) => {
    setImgContent(URL.createObjectURL(e[0]));
    setImgUpload(e);
  };

  const addInventarioStock = (e) => {
    if (talla === "" || cantidad === "") {
      swal(
        "UPPS! algo ha salido mal.",
        "talla y cantidad no pueden estar vacios",
        "info"
      );
      return;
    }
    setStock([...stock, `${talla}:${cantidad}`]);
    setTalla("");
    setCantidad("");
  };

  const enviar = async (e) => {
    e.preventDefault();
    if (imgUpload[0].size > 500000) {
      swal("Las imagenes deben pesar menos de 500kb");
      return;
    }
    let filesData = new FormData();
    for (let index = 0; index < 6; index++) {
      if (
        e.target[index].value !== "" &&
        typeof e.target[index].value === "string" &&
        stock !== ""
        ) {
          filesData.append(e.target[index].name, e.target[index].value);
        } else {
          swal("todos los campos son obligatorios");
          return;
        }
      }
    filesData.append("image", imgUpload[0]);
    filesData.append("stock", stock);
    const result = await createProductApi(url, "POST", filesData, token);
    if (result) {
      e.target.reset();
      setImgContent(imgPlaceholder);
      setStock("");
    }
  };

  return (
    <div className="form--product">
      <div className="form--product__container">
        <form onSubmit={(e) => enviar(e)} className="form--product__form">
          <img
            className="form--product__form--image"
            src={imgUpload ? imgContent : imgPlaceholder}
            alt="imgPlaceholder"
          />
          <label htmlFor="imagenProducto">Imagen producto</label>
          <input
            accept=".jpg"
            onChange={(e) => subirArchivos(e.target.files)}
            name="image"
            type="file"
            id="imagenProducto"
          />
          <label htmlFor="videoProducto">Video link</label>
          <input name="video" type="text" id="videoProducto" />
          <label htmlFor="nombreProducto">Nombre producto</label>
          <input name="nombre" type="text" id="nombreProducto" />
          <label htmlFor="precioProducto">Precio producto</label>
          <input name="precio" type="number" id="precioProducto" />
          <label htmlFor="preciointerno">Precio interno</label>
          <input name="preciointerno" type="number" id="preciointerno" />
          <label htmlFor="categoriaProducto">Categorias Producto</label>
          <input
            name="categorias"
            placeholder="deportivos,colegial,retro,adidas"
            type="text"
            id="categoriaProducto"
          />
          <div className="form--product__form--inventario">
            <label htmlFor="inventario">Invetario</label>
            <div className="form--product__form--inventario--stock">
              <input
                onChange={(e) => setTalla(e.target.value)}
                className="stock--talla"
                type="number"
                placeholder="talla"
                id="talla"
                value={talla ? talla : ""}
              />
              <input
                onChange={(e) => setCantidad(e.target.value)}
                className="stock--cantidad"
                type="number"
                placeholder="cantidad"
                id="cantidad"
                value={cantidad ? cantidad : ""}
              />
              <img
                onClick={(e) => addInventarioStock(e)}
                src={iconAddinventario}
                alt="icon login"
              />
            </div>
            <div className="form--product__form--inventario--view--stock">
              <div className="view--stock__title">
                <p>Tallas:</p>
                <p>Stock:</p>
              </div>
              {stock &&
                stock.map((e, i) => (
                  <StockInventario
                    x={true}
                    element={e}
                    stock={stock}
                    stockid={i}
                    key={i}
                    setStock={setStock}
                    talla={e.split(":")[0]}
                    cantidad={e.split(":")[1]}
                  />
                ))}
            </div>
          </div>
          <button type="submit">
            Agregar producto
            <img src={iconAddProduct} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormCreateProduct;
