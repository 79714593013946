import React, { useState, useEffect } from "react";
import swal from "sweetalert";

import SectionWhiteTwo from "../molecules/SectionWhiteTwo";
import ProductosEdit from "../molecules/ProductosEdit";
import closeBtn from "../../img/icons/carbon_close-filled.png";

const PageTiendaEdit = ({editProduct, setProductsNew, setOnpenShop }) => {
  const [productsCar, setProductsCar] = useState([]);
  const [total, setTotal] = useState("");
  const [category, setCategory] = useState("");
  const [filterCategory, setFilterCategory] = useState(null);


  const sendPreOrder = () => {
    if (productsCar.length > 0) {
      setProductsNew({
        total: total,
        productospedido: productsCar,
      });
      setOnpenShop(false);
      editProduct.total = Number(editProduct.total) + Number(total);
    } else {
      swal("debes agregar al menos un producto");
    }
  };

  return (
    <div className="page--base page--tienda">
      <img
        className="modal--shop__close"
        onClick={() => setOnpenShop(false)}
        width="50px"
        src={closeBtn}
      />
      <div className="page--base__container page--tienda__container">
        <div className="page--base__container--left page--tienda__container--left">
          <SectionWhiteTwo
            setFilterCategory={setFilterCategory}
            category={category}
          />
        </div>
        <div className="page--base__container--right page--tienda__container--right">
          <ProductosEdit
            filterCategory={filterCategory}
            setCategory={setCategory}
            total={total}
            setTotal={setTotal}
            sendPreOrder={sendPreOrder}
            productsCar={productsCar}
            setProductsCar={setProductsCar}
          />
        </div>
      </div>
    </div>
  );
};

export default PageTiendaEdit;
