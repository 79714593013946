import React, { useEffect, useState } from "react";
import getProducts from "../../helpers/getProducts";
import CardProduct from "../atoms/CardProduct";
import Spinner from "../atoms/Spinner";

import iconCarShopWithe from "../../img/icons/icon-car-white.png";
import iconTouchWhite from "../../img/icons/icon-touch-white.png";
import iconPDFBlack from "../../img/icons/icon-pdf-black.png";
import guiaTallas from "../../img/GuiaTallasTenisCucuta.jpeg";
import Back from "../atoms/Back";
import PopupC from "../atoms/PopupC";


const Productos = ({
  sendPreOrder,
  setProductsCar,
  productsCar,
  setTotal,
  total,
  setCategory,
  personalData,
  filterCategory,
  placeOrder,
  setPlaceOrder,
  setCantidad,
  cantidad,
  setTotalInterno
}) => {
  const url = process.env.REACT_APP_URL_API + "producto";
  const [products, setProducts] = useState("");
  const [productsTrue, setProductsTrue] = useState("");
  const [destacados, setDestacados] = useState([]);
  const [popupView, setPopupView] = useState(false);

  useEffect(() => {
    async function stockTienda() {
      let produtosAll = await getProducts(url);
      setDestacados(produtosAll.productoAll.filter(e => e.categorias.includes('destacado')));
      setProductsTrue(produtosAll.productoAll.filter(e => e.estadoproducto !== "Desactivado"));
      setProducts(produtosAll.productoAll.filter(e => e.estadoproducto !== "Desactivado"));
      const productosFilter = produtosAll.productoAll.filter(e => e.estadoproducto !== "Desactivado");
      let categoryRaw = productosFilter.map((e) =>
        e.categorias.split(",")
      );
      let categotyPlain = categoryRaw.flat(2);
      let categotyTrim = categotyPlain.map((e) => e.trim());
      let categotyClean = new Set(categotyTrim);
      setCategory([...categotyClean]);
    }
    setTimeout(() => {
      setPopupView(true)
    }, 6000);
    stockTienda();
  }, []);

  useEffect(() => {
    if (filterCategory) {
      if (filterCategory === "all") {
        setProductsTrue(products);
        return;
      }
      const productsFilter = products.map((e) => {
        let categoriasSplit = e.categorias.split(",");
        let categoriasValidate = categoriasSplit.filter((cat) =>
          cat.trim().includes(filterCategory)
        );
        if (categoriasValidate.length > 0) {
          return e;
        }
      });
      setProductsTrue(productsFilter.filter((e) => e !== undefined));
    } else {
      setProductsTrue(products);
    }
  }, [filterCategory]);

  useEffect(() => {
    getTotal();
  }, [productsCar]);

  const getTotal = () => {
    const totalScore = productsCar.map((e) => e.precio);
    setTotal(
      totalScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
    const totalScoreInterno = productsCar.map((e) => e.preciointerno);
    setTotalInterno(
      totalScoreInterno.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );

    const totalCantidad = productsCar.map((e) => Number(e.cantidad));
    setCantidad(
      totalCantidad.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  };

  return (
    <div className="productos--page">
      <header className="productos--page__header">
        {/* <Back placeOrder={placeOrder} setPlaceOrder={setPlaceOrder} /> */}
        <button className="guia--tallas">
          <a href={guiaTallas} target="_blank">
            <p>Guia de tallas</p>{" "}
          </a>
        </button>
        <div className="carrito">
          <div className="carrito--info">
            <img src={iconCarShopWithe} alt="icon tenis Cúcuta" />
            <div>
              <p>
                Cantidad: <span>{cantidad}</span>
              </p>
              <p>
                Total: <span>${total}</span>{" "}
              </p>
            </div>
          </div>
          <p className="carrito--user__name">{personalData.nombre}</p>
          <button onClick={() => sendPreOrder()} className="carrito--pedido">
            <p>Ver pedido</p> <img src={iconTouchWhite} alt="" />
          </button>
        </div>
      </header>
      {popupView && <PopupC setPopupView={setPopupView} popupView={popupView} />}
      <div className="productos--page__tienda tienda--productos destacados">
        {/* <h2>Productos destacados</h2> */}
        {destacados ? (
          destacados.map((element, i) => (
            <CardProduct
              productsCar={productsCar}
              setProductsCar={setProductsCar}
              key={i}
              element={element}
            />
          ))
        ) : (
          <Spinner />
        )}
      </div>
      <div className="productos--page__tienda tienda--productos">
        {/* <h2>Productos</h2> */}
        {productsTrue ? (
          productsTrue.map((element, i) => (
            <CardProduct
              productsCar={productsCar}
              setProductsCar={setProductsCar}
              key={i}
              element={element}
            />
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default Productos;
