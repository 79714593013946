import swal from "sweetalert";

const getRemitente = async (url,method, token, body) => {
  const req = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
    },
    body: JSON.stringify(body),
  });
  if (req.ok) {
    const res = await req.json();
    if (req.status === 201) {
      swal(res.msg)
    }
    return res;
  } else {
    swal("algo ha salido mal en la peticion, contacte con el soporte técnico");
  }
};
export default getRemitente;