import React, { useEffect, useState } from "react";
import getGuias from "../../helpers/getGuias";
import Spinner from "../atoms/Spinner";
import BtnClose from "../atoms/BtnClose";

import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
} from "@react-pdf/renderer";
// import img from '../../img/bgs/logo.png'

const PagePDFguiaTotal = ({setNavBar, navBar, orderReportes, reporteGuias }) => {
  const [guia, setGuia] = useState();
  const [orden, setOrden] = useState();

  const guiasFilter = []
  reporteGuias.filter(guia => {
    orderReportes.forEach(pedido => {
        if (pedido.id === Number(guia.pedidoID)) {
          if (pedido.estadopedido !== 'Desactivado') {
            guiasFilter.push(guia) 
          }
        }
      });
  } );

  if (guiasFilter && orderReportes) {
    return (
      <div className="pdf--guia">
        <BtnClose  navBar={navBar} setNavBar={setNavBar} />
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document>
            <Page style={{ marginTop: "1cm" }}>
              {guiasFilter.map((e, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      color: "#5E5E5E",
                      fontSize: "12px",
                      margin: "0 1cm 0 1cm",
                      height: "50%",
                    }}
                  >
                    <View>
                      <Text
                        style={{ backgroundColor: "#dddddd", padding: "5px" }}
                      >
                        <Text style={{ color: "black" }}>REMITENTE</Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "white", padding: "5px" }}
                      >
                        <Text style={{ marginRight: "10px" }}>NOMBRE: </Text>
                        <Text>{e.nombreremitente}</Text>
                        <Text> CEDULA: </Text>
                        <Text>{e.cedularemitente} </Text>
                        <Text> PEDIDO # :</Text>
                        <Text>{e.pedidoID}</Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "white", padding: "5px" }}
                      >
                        <Text style={{ marginRight: "10px" }}>DIRECCION: </Text>
                        <Text>{e.direccionremitente} </Text>
                        <Text> TELEFONO: </Text>
                        <Text>{e.telefonoremitente} </Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "#dddddd", padding: "5px" }}
                      >
                        <Text style={{ color: "black" }}>DESTINATARIO</Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "white", padding: "5px" }}
                      >
                        <Text style={{ marginRight: "10px" }}>NOMBRE: </Text>
                        <Text>{e.destinatarionombre}</Text>
                        <Text> CEDULA: </Text>
                        <Text>{e.destinatariocedula} </Text>
                        <Text> TELEFONO: </Text>
                        <Text>{e.destinatariotelefono}</Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "white", padding: "5px" }}
                      >
                        <Text style={{ color: "black" }}>DIRECCION: </Text>
                        <Text style={{ color: "black" }}>
                          {e.destinatariodireccion}{" "}
                        </Text>
                      </Text>
                      {orderReportes.map((element, i) => {
                        if (element.id === Number(e.pedidoID)) {
                            return (
                              <Text
                              key={i}
                                style={{
                                  backgroundColor: "white",
                                  padding: "5px",
                                  textTransform: "uppercase",
                                }}
                              >
                                <Text style={{ color: "black" }}>
                                  TIPO DE ENVIO: {element.tipoenvio}
                                </Text>
                              </Text>
                            );
                          }
                        })}
                      <Text
                        style={{ backgroundColor: "#dddddd", padding: "5px" }}
                      >
                        <Text style={{ color: "black" }}>PRODUCTOS</Text>
                      </Text>
                      <Text
                        style={{ backgroundColor: "white", padding: "5px" }}
                        className="guia--PDF__pedido"
                      >
                        {orderReportes.map((element, i) => {
                          if (element.id === Number(e.pedidoID)) {
                            let pedidoPorId = JSON.parse(
                              element.productospedido
                            );
                            return pedidoPorId.map((p, i) => {
                              return (
                                <Text  key={i}>
                                  {p.nombre} {p.talla} / {p.cantidad} - {" "}
                                </Text>
                              );
                            });
                          }
                        })}
                      </Text>

                      <Text style={{ color: "#ddd" }}>
                        ---------------------------------------------------------------------------------------------------------------------------------------
                      </Text>
                      <Text> </Text>
                    </View>
                  </View>
                );
              })}
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
};

export default PagePDFguiaTotal;
