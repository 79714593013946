import React, { useEffect, useState } from "react";
import createProductApi from "../../helpers/createProductApi";
import getProducts from "../../helpers/getProducts";
import Search from "../atoms/Search";
import Spinner from "../atoms/Spinner";
import StockInventario from "../atoms/StockInventario";
import ModalCreateProduct from "../organisms/ModalCreateProduct";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import iconAdd from "../../img/icons/icon-add.png";

const ProductsList = ({ proDisable, navBar, setNavBar, token }) => {
  const [products, setProducts] = useState();

  const url = process.env.REACT_APP_URL_API + "producto";
  const urlFiles = process.env.REACT_APP_URL_API + "files/";
  const navigate = useNavigate();

  const [consulta, setConsulta] = useState();
  const [productsTrue, setProductsTrue] = useState();
  const [toAdd, setToAdd] = useState(false);
  const [productEdit, setProductEdit] = useState();
  const [count, setCount] = useState(null)

  useEffect(() => {
    if (consulta) {
      if (products) {
       consulta.palabra ?
        setProductsTrue(
          products.filter((e) => {
            let arr = Object.keys(e).map(function (key) {
              return [key, `${e[key]}`];
            });
            let res = arr.filter((p) => p[1].toLocaleLowerCase().includes(consulta.palabra.toLowerCase()));
            if (res.length > 0) {
              return e;
            }
          })
        )
        : setProductsTrue(products);
      }
    } 
  }, [count, consulta]);

  useEffect(() => {
    setProductsTrue(products);
  }, [products]);

  useEffect(() => {
    const getProdusctRaw = async (e) => {
      const productsRaw = await getProducts(url, "GET");
      if (!proDisable) {
        setProducts(
          productsRaw.productoAll.filter(
            (producto) => producto.estadoproducto === "Activo"
          )
        );
      } else {
        setProducts(
          productsRaw.productoAll.filter(
            (e) => e.estadoproducto === "Desactivado"
          )
        );
      }
    };
    getProdusctRaw();
  }, [proDisable]);

  const handleProduct = (e) => {
    setToAdd(true);
    setProductEdit(e);
  };

  const disableProduct = async (event, e) => {
    event.preventDefault();
    const reult = await swal("Deseas Desactivar este producto", {
      buttons: ["Cancelar", "Aceptar"],
    });
    if (reult) {
      const url = `${process.env.REACT_APP_URL_API}producto/${e.id}`;
      const result = await createProductApi(url, "DELETE", "", token);
      navigate("/pedidos");
    }
  };

  const activateProduct = async (event, e) => {
    event.preventDefault();
    const reult = await swal("Deseas Activar este producto", {
      buttons: ["Cancelar", "Aceptar"],
    });
    if (reult) {
      const url = `${process.env.REACT_APP_URL_API}producto/activate/${e.id}`;
      const result = await createProductApi(url, "PUT", "", token);
      navigate("/pedidos");
    }
  };

  if (toAdd) {
    return (
      <ModalCreateProduct
        setToAdd={setToAdd}
        productEdit={productEdit}
        token={token}
        setNavBar={setNavBar}
        navBar={navBar}
      />
    );
  } else if (!toAdd) {
    return (
      <div className="products--list">
        <div className="produts--list__actions">
          <Search
            count={count}
            setCount={setCount}
            navBar={navBar}
            consulta={consulta}
            setConsulta={setConsulta}
          />
          <button className="produts--list__actions--add" onClick={() => setToAdd(true)}>
            Agregar <img src={iconAdd} alt="agreagr producto" />
          </button>
        </div>
        <div className="container--table">
          <table className="products--list__table orders--table">
            <tr className="products--list__table--indice table--header">
              <td>N° Producto</td>
              <td>imagen</td>
              <td>Nombre</td>
              <td>Inventario</td>
              <td>Precio</td>
              <td>Precio interno</td>
              <td>Categorias</td>
              <td>Acciones</td>
            </tr>
            {productsTrue ? (
              productsTrue.map((e, i) => (
                <tr
                  className="products--list__table--product table--item"
                  key={i}
                >
                  <td>{`${e.id}`}</td>
                  <td>
                    <img src={urlFiles + e.imagen} alt={e.nombre} />
                  </td>
                  <td>{e.nombre}</td>
                  <td className="product--stock">
                    {e.stock.split(",").map((e, i) => (
                      <StockInventario
                        key={i}
                        x={false}
                        talla={e.split(":")[0]}
                        cantidad={e.split(":")[1]}
                      />
                    ))}
                  </td>
                  <td>{e.precio}</td>
                  <td>{e.preciointerno}</td>
                  <td>{e.categorias}</td>
                  <td className="table--item__actions">
                    <button
                      className="table--item__actions--admin"
                      onClick={() => handleProduct(e)}
                    >
                      Administrar
                    </button>
                    {proDisable ? (
                      <button
                        style={{ backgroundColor: "#43BF39" }}
                        onClick={(event) => activateProduct(event, e)}
                        className="table--item__actions--disable"
                      >
                        Activar
                      </button>
                    ) : (
                      <button
                        onClick={(event) => disableProduct(event, e)}
                        className="table--item__actions--disable"
                      >
                        Desactivar
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <Spinner />
            )}
          </table>
        </div>
      </div>
    );
  }
};

export default ProductsList;
