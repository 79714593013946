import React, { useEffect, useState } from "react";
import iconCheckTrue from "../../img/icons/icon-check-true.png";
import iconCheckFalse from "../../img/icons/icon-check-false.png";
import iconDelete from "../../img/icons/icon-delete.png";

const NavBarOrders = ({setNavBar, navBar, changeOrderNav }) => {
  const [papelera, setPapelera] = useState(false);
  const handlePapelera = (bool) => {
    setPapelera(bool);
    bool && changeOrderNav(window.localStorage.getItem('navBarStatus'));
    !bool && changeOrderNav(window.localStorage.getItem('navBarStatus'));
  };

  // useEffect(()=> {
  //   window.localStorage.getItem('navBarStatus') != 'undefined'
  //   ? setNavBar(window.localStorage.getItem('navBarStatus'))
  //   : setNavBar('pedidos')
  // })
  return (
    <div className="navbar--orders">
      {papelera ? (
        <div className="navbar--orders__container papelera">
          <div onClick={() => changeOrderNav("pedidosDesactivados")}>
            <img
              src={
                navBar === "pedidosDesactivados"
                  ? iconCheckTrue
                  : iconCheckFalse
              }
              alt="check"
            />
            <p>Pedidos desactivados</p>
          </div>
          <div onClick={() => changeOrderNav("productosDesactivados")}>
            <img
              src={
                navBar === "productosDesactivados"
                  ? iconCheckTrue
                  : iconCheckFalse
              }
              alt="check"
            />
            <p>Productos desactivados</p>
          </div>
          <div className="navbar--orders__btn--papelera">
            <button onClick={() => handlePapelera(false)}>
              Menu principal
            </button>
          </div>
        </div>
      ) : (
        <div className="navbar--orders__container">
          <div onClick={() => changeOrderNav("pedidos")}>
            <img
              src={navBar === "pedidos" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Pedidos</p>
          </div>
          <div onClick={() => changeOrderNav("Preparando")}>
            <img
              src={navBar === "Preparando" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Preparando</p>
          </div>
          <div onClick={() => changeOrderNav("envios")}>
            <img
              src={navBar === "envios" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Envios</p>
          </div>
          <div onClick={() => changeOrderNav("productos")}>
            <img
              src={navBar === "productos" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Productos</p>
          </div>
          {/* <div onClick={() => changeOrderNav("facturas")}>
            <img
              src={navBar === "facturas" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Facturas</p>
          </div>
          <div onClick={() => changeOrderNav("guias")}>
            <img
              src={navBar === "guias" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Guias</p>
          </div> */}
          <div onClick={() => changeOrderNav("reportes")}>
            <img
              src={navBar === "reportes" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Reportes</p>
          </div>
          <div onClick={() => changeOrderNav("usuarios")}>
            <img
              src={navBar === "usuarios" ? iconCheckTrue : iconCheckFalse}
              alt="check"
            />
            <p>Usuarios</p>
          </div>

          {/* {navBar === "reportes" && (
            <div>
              <input placeholder="Buscar referencias" type="date" />
              <input placeholder="Buscar referencias" type="date" />
            </div>
          )} */}
          <div className="navbar--orders__btn--papelera">
            <button onClick={() => handlePapelera(true)}>
              Papelera <img src={iconDelete} alt="iconDelete" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarOrders;
