import React from "react";
import logo from "../../img/logo.png";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";


const PageAnimationHome = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate('/home');
  }, 3000);

  return (
    <div className="animation--home">
      <img
        className="animation--home__logo"
        src={logo}
        alt="logo tenis Cúcuta"
      />
      {/* <Navigate to={<PageLogin/>}/> */}
    </div>
  );
};

export default PageAnimationHome;
