import React, { useState } from "react";
import swal from "sweetalert";
// import PDFcomponent from "./PDFcomponent";
// import { read, utils, writeFileXLSX } from "xlsx";
import { exportFile } from "../../helpers/toExcel";
import PagePDFguiaTotal from "../pages/PagePDFguiasTotal";

const Reportes = ({ navBar, setNavBar, guias, ordersAll, orderReportes }) => {
  const fechaHoy = new Date();
  const [fechaInit, setFechaInit] = useState(fechaHoy.toJSON().slice(0, 10));
  const [fechaEnd, setFechaEnd] = useState(fechaHoy.toJSON().slice(0, 10));
  const [reportePedidos, setReportePedidos] = useState(null);
  const [reporteGuias, setReporteGuias] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    const reportesOrdersGet = await orderReportes.filter((e) => {
      const fechaPedido = new Date(e.updatedAt.slice(0, 10).split("-"));
      const fechainicio = new Date(fechaInit.split("-"));
      const fechafinal = new Date(fechaEnd.split("-"));
      if (fechaPedido >= fechainicio && fechaPedido <= fechafinal) {
        return e;
      }
    });
    setReportePedidos(reportesOrdersGet);
    swal('Fecha Actualizada', 'ya puedes descargar el reporte');
  };

  const handleOrderExcel = (e) => {
    e.preventDefault();
    const ultimateReporte = reportePedidos.map(element => {
      const cantidad = JSON.parse(element.productospedido).map(e => Number(e.cantidad))
      const total = cantidad.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
      return {
        ...element,
        cantidad: total
      }
    })
    reportePedidos && exportFile(ultimateReporte);
  };
  const handleGuiasPDF = async (e) => {
    e.preventDefault();
    const reportesGuiasGet = await guias.filter((e) => {
      const fechaGuia = new Date(e.updatedAt.slice(0, 10).split("-"));
      const fechainicio = new Date(fechaInit.split("-"));
      const fechafinal = new Date(fechaEnd.split("-"));
      if (fechaGuia >= fechainicio && fechaGuia <= fechafinal) {
        return e;
      }
    });
    setReporteGuias(reportesGuiasGet);
    setOpenModal(true);
  };
  return (
    <div className="orders reportes">
      {openModal ? (
        <PagePDFguiaTotal
          orderReportes={orderReportes}
          reporteGuias={reporteGuias}
          setNavBar={setNavBar}
          navBar={navBar}
        />
      ) : (
        <>
          <div className="reportes--search">
            <form className="reportes--search__form">
              <div className="reportes--search__form--input">
                <label htmlFor="date-init">Desde</label>
                <input
                  onChange={(e) => setFechaInit(e.target.value)}
                  defaultValue={fechaHoy.toJSON().slice(0, 10)}
                  id="date-init"
                  type="date"
                />
              </div>
              <div className="reportes--search__form--input">
                <label htmlFor="date-end">Hasta</label>
                <input
                  onChange={(e) => setFechaEnd(e.target.value)}
                  defaultValue={fechaHoy.toJSON().slice(0, 10)}
                  id="date-end"
                  type="date"
                />
              </div>
              <button
                onClick={(e) => handleClick(e)}
                className="reportes--search__form--btn"
                type="submit"
              >
                Consultar
              </button>
            </form>
          </div>
          <div className="container--table reportes">
            <table className="orders--table">
              <tr className="table--header">
                <td>N°</td>
                <td>Tipo</td>
                <td>Acciones</td>
              </tr>
              <tr className="table--item">
                <td>{1}</td>
                <td>Pedidos</td>
                <td className="table--item__actions">
                  <button onClick={(e) => handleOrderExcel(e)}>Desargar</button>
                </td>
              </tr>
              <tr className="table--item">
                <td>{2}</td>
                <td>Guias</td>
                <td className="table--item__actions">
                  <button onClick={(e) => handleGuiasPDF(e)}>Desargar</button>
                </td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Reportes;
