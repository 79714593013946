import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Page404 from "./components/pages/Page404";
import PageAnimationHome from "./components/pages/PageAnimationHome";
import PageHome from "./components/pages/PageHome";
import PageLogin from "./components/pages/PageLogin";
import ModalStatusOrder from "./components/organisms/ModalStatusOrder";
import PageConfirmation from "./components/pages/PageConfirmation";
import PageTienda from "./components/pages/PageTienda";
import PageOrders from "./components/pages/PageOrders";

import validateTokenStorage from "./helpers/validateTokenStorage";
import { useNavigate } from "react-router-dom";
import PagePDFguia from "./components/pages/PagePDFguia";
import Popup from "./components/organisms/Popup";
import Politicas from "./components/pages/Politicas";


export const Dcontex = React.createContext(null);
function App() {
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [loginTrue, setLoginTrue] = useState("");
  const [datosContex, setDatosContex] = useState(null);

  useEffect(() => {
    if (window.location.href.includes("pedidos")) {
      const validationLogin = async () => {
        const result = await validateTokenStorage();
        setLoginTrue(result);
        result && setToken(result);
        !result && navigate("/login");
        // window.location.reload();
      };
      validationLogin();
    }
    async function obtenerDireccionIP() {
      try {
        const respuesta = await fetch('https://ipapi.co/json/');
        const datos = await respuesta.json();
        setDatosContex(datos)
      } catch (error) {
        console.log(error);
      }
    }

    obtenerDireccionIP();
  }, []);
  // console.log(datosContex)
  const ProtectedRoute = ({ children }) => {
    if (loginTrue) {
      return children;
    }
  };

  return (
    <>
      <Dcontex.Provider value={datosContex}>
        <Routes>
          <Route path="/" element={<PageAnimationHome />} />
          <Route path="/home" element={<PageHome />} />
          <Route path="/login" element={<PageLogin />} />
          <Route path="/order" element={<ModalStatusOrder />} />
          <Route path="/confirmacion" element={<PageConfirmation />} />
          <Route path="/tienda" element={<PageTienda />} />
          <Route path="/pdf" element={<PagePDFguia />} />
          <Route path="/popup" element={<Popup />} />
          <Route path="/politicas" element={<Politicas />} />
          <Route
            path="/pedidos"
            element={
              <ProtectedRoute>
                <PageOrders loginTrue={loginTrue} token={token} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Dcontex.Provider>
    </>
  );
}

export default App;
