import React, { useEffect, useState } from "react";
import FormPersonalDataEdit from "../molecules/FormPersonalDataEdit";
import CheckOrderEdit from "../molecules/CheckOrderEdit";
import PageTiendaEdit from "../pages/PageTiendaEdit";
import BtnClose from "../atoms/BtnClose";

const ModalEditOrder = ({ setEditProduct, editProduct, setClose, token, setNavBar, navBar }) => {
  const [personalData, setPersonalData] = useState({});
  const [onpenShop, setOnpenShop] = useState(false);
  const [productsNew, setProductsNew] = useState();
  const [productOrder, setProductOrder] = useState();
  const [updatePrice, setUpdatePrice] = useState();
  // console.log(productsNew);
  useEffect(() => {
    if (!productsNew) {
       // old
      setProductOrder(JSON.parse(editProduct.productospedido));
      // local
      // setProductOrder(editProduct.productospedido);
    } else {
      setProductOrder([...productOrder, productsNew.productospedido].flat(2));
    }
  }, [productsNew]);
  return (
    <div className="modal--order">
      <BtnClose navBar={navBar} setClose={setClose} setNavBar={setNavBar}/>
     
      {onpenShop && (
        <PageTiendaEdit
          setOnpenShop={setOnpenShop}
          setProductsNew={setProductsNew}
        />
      )}

      <div className="modal--order__container">
        <div className="modal--order__left">
        {editProduct && <h1 className="numero-pedido">N° Pedido {editProduct.id}</h1>}
          {!onpenShop && (
            <FormPersonalDataEdit
              setEditProduct={setEditProduct}
              updatePrice={updatePrice}
              token={token}
              productOrder={productOrder}
              setOnpenShop={setOnpenShop}
              setPersonalData={setPersonalData}
              editProduct={editProduct}
            />
          )}
        </div>
        <div className="modal--order__right">
          {!onpenShop && (
            <CheckOrderEdit
              setEditProduct={setEditProduct}
              setProductOrder={setProductOrder}
              productOrder={productOrder}
              productsNew={productsNew}
              editProduct={editProduct}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalEditOrder;
