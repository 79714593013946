import React, { useEffect, useState } from 'react'
import getConfirmacion from '../../helpers/getConfirmacion';
import Back from '../atoms/Back';
import ConfirmationInfo from '../molecules/ConfirmationInfo';

const PageConfirmation = ({orderSend,placeOrder,setPlaceOrder}) => {
  const url = process.env.REACT_APP_URL_API + "confirmacion";
  const [data, setdata] = useState({});

  useEffect(() => {
    const obtenerCorfirmacion = async () => {
      const c = await getConfirmacion(url, "GET");
      setdata(c.confirmacionAll[0]);
    };
    obtenerCorfirmacion();
  }, []);
    return (
        <div className="page--base">
              {/* <Back placeOrder={placeOrder} setPlaceOrder={setPlaceOrder} /> */}
              <ConfirmationInfo orderSend={orderSend} data={data} />
        </div>
      );
}

export default PageConfirmation