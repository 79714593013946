import React from 'react'
import CopyrightSite from '../atoms/CopyrightSite'
import LogoSite from '../atoms/LogoSite'


const SectionWhitethree = () => {

  return (
    <div className='seccion--white__three'>
      <div className='seccion--white__three__container'>
        <LogoSite/>
        <CopyrightSite/>
      </div>
    </div>
  )
}

export default SectionWhitethree