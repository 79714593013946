import React from "react";

const StockInventario = ({x,setStock,stock, element, talla, cantidad }) => {

  const deleteStock = (e)=> {
    e.preventDefault();
    setStock(stock.filter(e => e !== element))
  }
  return (
    <div className="view--stock__inventario">
      <p className="view--stock__inventario--talla">{talla}</p>
      <p className="view--stock__inventario--cantidad">{cantidad}</p>
      {x && <p onClick={(e)=> deleteStock(e)} className="view--stock__inventario--delete">x</p>}
    </div>
  );
};

export default StockInventario;
