// system
import React, { useState, useEffect } from "react";
import getOrders from "../../helpers/getOrders";
import { useNavigate } from "react-router-dom";
// Components
import Orders from "../molecules/Orders";
import NavBarOrders from "../molecules/NavBarOrders";
import ProductsList from "../molecules/ProductsList";
import FormRemitente from "../molecules/FormRemitente";
import Users from "../molecules/Users";
import AdminPanel from "../atoms/AdminPanel";
import Reportes from "../molecules/Reportes";
import ModalEditOrder from "../organisms/ModalEditOrder";
import FormConfirmationData from "../molecules/FormConfirmationData";
import SectionWhitethree from "../molecules/SectionWhitethree";
import swal from "sweetalert";
import Popup from "../organisms/Popup";

// files

const PageOrders = ({ token, loginTrue }) => {
  const url = process.env.REACT_APP_URL_API + "pedido";
  const navigate = useNavigate();
  const [navBar, setNavBar] = useState("pedidos");
  const [ordersAll, setOrdersAll] = useState("");
  const [ordersSend, setOrdersSend] = useState("");
  const [ordersDes, setOrdersDes] = useState("");
  const [ordersPre, setOrdersPre] = useState("");
  const [guias, setGuias] = useState("");
  const [facturas, setFacturas] = useState("");
  const [editProduct, setEditProduct] = useState(null);
  const [close, setClose] = useState(false);
  const [orderReportes, setOrderReportes] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    !loginTrue && navigate("/login"); //pendiente de reparar...
    setIsMobile(window.innerWidth);

    const getOrdersRaw = async () => {
      const ordersRaw = await getOrders(url, "GET", token);
      setOrderReportes(ordersRaw.pedidoAll);
      ordersRaw.pedidoAll.forEach((element) => { });
      setOrdersAll(
        ordersRaw.pedidoAll.filter((e) => {
          if (
            e.estadopedido !== "Pedido enviado" &&
            e.estadopedido !== "Desactivado" &&
            e.estadopedido !== 'Preparando pedido'
          ) {
            return e;
          }
        })
      );
      setOrdersSend(
        ordersRaw.pedidoAll.filter((e) => e.estadopedido === "Pedido enviado")
      );
      setOrdersDes(
        ordersRaw.pedidoAll.filter((e) => e.estadopedido === "Desactivado")
      );
      setOrdersPre(
        ordersRaw.pedidoAll.filter((e) => e.estadopedido === "Preparando pedido")
      );
      setGuias(ordersRaw.guiaAll);
      setFacturas(ordersRaw.facturaAll);
    };
    token && getOrdersRaw();

    window.localStorage.getItem('navBarStatus') != 'undefined'
      ? setNavBar(window.localStorage.getItem('navBarStatus'))
      : setNavBar('pedidos')

  }, []);

  const changeOrderNav = (changeNav) => {
    setNavBar(changeNav);
    window.localStorage.setItem('navBarStatus', changeNav)
  };
  const logout = (e) => {
    localStorage.removeItem("user-token");
    navigate("/login");
    window.location.reload();
  };

  const adminProduct = (e) => {
    setEditProduct(e);
    setClose(true);
  };
  const navBarComponent = (navBar) => {
    switch (navBar) {
      case "pedidos":
        return (
          <Orders
            setClose={setClose}
            adminProduct={adminProduct}
            navBar={navBar}
            order={ordersAll}
            token={token}
            facturas={facturas}
            guias={guias}
            isDisable={false}
            setNavBar={setNavBar}
          />
        );
      case "envios":
        return (
          <Orders
            setClose={setClose}
            adminProduct={adminProduct}
            navBar={navBar}
            order={ordersSend}
            token={token}
            facturas={facturas}
            guias={guias}
            isDisable={false}
            setNavBar={setNavBar}
          />
        );
      case "Preparando":
        return (
          <Orders
            setClose={setClose}
            adminProduct={adminProduct}
            navBar={navBar}
            order={ordersPre}
            token={token}
            facturas={facturas}
            guias={guias}
            isDisable={false}
            setNavBar={setNavBar}
          />
        );
      case "pedidosDesactivados":
        return (
          <Orders
            setClose={setClose}
            adminProduct={adminProduct}
            navBar={navBar}
            order={ordersDes}
            token={token}
            facturas={facturas}
            guias={guias}
            isDisable={true}
            setNavBar={setNavBar}
          />
        );
      // case "facturas":
      //   return <Facturas navBar={navBar} facturas={facturas} token={token} />;
      // case "guias":
      //   return <Guias navBar={navBar} guias={guias} token={token} />;
      case "productos":
        return (
          <ProductsList setNavBar={setNavBar} navBar={navBar} proDisable={false} token={token} />
        );
      case "productosDesactivados":
        return <ProductsList setNavBar={setNavBar} navBar={navBar} proDisable={true} token={token} />;
      case "usuarios":
        return <Users token={token} />;
      case "remitente":
        return <FormRemitente token={token} />;
      case "popup":
        return <Popup token={token} />;
      case "reportes":
        return (
          <Reportes
            setNavBar={setNavBar}
            navBar={navBar}
            orderReportes={orderReportes}
            ordersAll={ordersAll}
            token={token}
            guias={guias}
          />
        );
      case "confirmacion":
        return <FormConfirmationData token={token} />;
      default:
        break;
    }
  };

  return (
    <div className="page--base page--orders">
      {close && (
        <ModalEditOrder
          setNavBar={setNavBar}
          token={token}
          setClose={setClose}
          setEditProduct={setEditProduct}
          editProduct={editProduct}
          navBar={navBar}
        />
      )}
      {!close && (
        <div className="page--base__container page--orders__container">
          {isMobile <= 768 ? (
            <div className="page--base__container--left page--orders__container--left">
              <SectionWhitethree />
            </div>
          ) : null}
          <div className="page--base__container--right page--orders__container--right">
            <div className="page--orders__header">
              <AdminPanel changeOrderNav={changeOrderNav} logout={logout} />
              <NavBarOrders setNavBar={setNavBar} navBar={navBar} changeOrderNav={changeOrderNav} />
            </div>
            {navBarComponent(navBar)}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageOrders;
