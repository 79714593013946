import React, { useEffect, useState } from "react";
import getGuias from "../../helpers/getGuias";
import Spinner from "../atoms/Spinner";
import BtnClose from "../atoms/BtnClose";

import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
} from "@react-pdf/renderer";
// import img from '../../img/bgs/logo.png'

const PagePDFguia = ({navBar,setNavBar, tipoDeEnvio,  token, guiaPDF, pedido }) => {
  const [guia, setGuia] = useState();

  useEffect(() => {
    if (guiaPDF && pedido) {
      const url = process.env.REACT_APP_URL_API + "guia/" + guiaPDF;
      const obtenetGuia = async () => {
        const guia = await getGuias(url, "GET", token);
        setGuia(guia.guiaAll[0]);
      };
      obtenetGuia();
    }
  }, [guiaPDF]);

  if (guia) {
    return (
      <div className="pdf--guia">
        <BtnClose navBar={navBar} setNavBar={setNavBar}/>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document>
            <Page style={{ marginTop: "2cm" }}>
              <View
                style={{
                  color: "#5E5E5E",
                  fontSize: "12px",
                  margin: "0 1cm 2cm 1cm",
                }}
              >
                <View>
                  <Text style={{ backgroundColor: "#dddddd", padding: "5px" }}>
                    <Text style={{ color: "black" }}>REMITENTE</Text>
                  </Text>
                  <Text style={{ backgroundColor: "white", padding: "5px" }}>
                    <Text style={{ marginRight: "10px" }}>NOMBRE: </Text>
                    <Text>{guia.nombreremitente}</Text>
                    <Text> CEDULA: </Text>
                    <Text>{guia.cedularemitente} </Text>
                    <Text> PEDIDO # :</Text>
                    <Text>{guiaPDF}</Text>
                  </Text>
                  <Text style={{ backgroundColor: "white", padding: "5px" }}>
                    <Text style={{ marginRight: "10px" }}>DIRECCION: </Text>
                    <Text>{guia.direccionremitente} </Text>
                    <Text> TELEFONO: </Text>
                    <Text>{guia.telefonoremitente} </Text>
                  </Text>
                  <Text style={{ backgroundColor: "#dddddd", padding: "5px" }}>
                    <Text style={{ color: "black" }}>DESTINATARIO</Text>
                  </Text>
                  <Text style={{ backgroundColor: "white", padding: "5px" }}>
                    <Text style={{ marginRight: "10px" }}>NOMBRE: </Text>
                    <Text>{guia.destinatarionombre}</Text>
                    <Text> CEDULA: </Text>
                    <Text>{guia.destinatariocedula} </Text>
                    <Text> TELEFONO: </Text>
                    <Text>{guia.destinatariotelefono}</Text>
                  </Text>
                  <Text style={{ backgroundColor: "white", padding: "5px" }}>
                    <Text style={{ color: "black" }}>DIRECCION: </Text>
                    <Text style={{ color: "black" }}>
                      {guia.destinatariodireccion}{" "}
                    </Text>
                  </Text>
                  {tipoDeEnvio && 
                  <Text style={{ backgroundColor: "white", padding: "5px" }}>
                    <Text style={{ color: "black", textTransform: 'uppercase' }}>TIPO DE ENVIO: {tipoDeEnvio} </Text>
                  </Text>
                  }
                  <Text style={{ backgroundColor: "#dddddd", padding: "5px" }}>
                    <Text style={{ color: "black" }}>PRODUCTOS</Text>
                  </Text>
                  <Text
                    style={{ backgroundColor: "white", padding: "5px" }}
                    className="guia--PDF__pedido"
                  >
                    {pedido &&
                      pedido.map((e, i) => <Text key={i}>{e} - </Text>)}
                  </Text>
                  <Text style={{ color: "#ddd" }}>
                    ---------------------------------------------------------------------------------------------------------------------------------------
                  </Text>
                  <Text> </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
};

export default PagePDFguia;
