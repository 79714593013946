import React from 'react'
import logo from '../../img/logo.png'
const LogoSite = () => {
  return (
    <div className='logo--site'>
      <img  className='logo--site__logo' src={logo} alt="logo Tenis Cúcuta"/>
    </div>
  )
}

export default LogoSite