import React, { useState } from "react";
import CopyrightSite from "../atoms/CopyrightSite";
import LogoSite from "../atoms/LogoSite";
import MenuBurguer from "../atoms/MenuBurguer";

const SectionWhiteTwo = ({ category, setFilterCategory }) => {
  const [consulta, setConsulta] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [close, setClose] = useState("");


  const searchCategory = () => {
    consulta === ""
      ? setFilterCategory("all")
      : setFilterCategory(consulta.toLowerCase());
  };
  const clickCategory = (evento, categoryName) => {
    evento.currentTarget.style.color = 'green'; 
    evento.preventDefault();
    const handleClose = (e) => {
      setClose("seccion--search__movile--close");
      setTimeout(() => {
        setOpenMenu(false);
      }, 1000);
    };
    handleClose();
    setFilterCategory(categoryName);
  };

  return (
    <div className="seccion--white">
      {window.innerWidth > 768 ? (
        <div className="seccion--white__container">
          <div className="menu--desktop__container">
            <LogoSite />
            <div className="seccion--search__desktop">
              <input
                onChange={(e) => setConsulta(e.target.value)}
                type="search"
                id="site-search"
                name="q"
              />
              <button onClick={(e) => searchCategory(e)}>Buscar</button>
            </div>
            <nav className=" seccion--search__desktop--menu">
              <ul className="seccion--search__category--list">
                <li className="seccion--search__category--item">
                  <button onClick={(evento) => clickCategory(evento, "all")}>
                    Todas las categorias
                  </button>
                </li>
                {category &&
                  category.map((e, i) => (
                    <li className="seccion--search__category--item" key={i}>
                      <button onClick={(evento) => clickCategory(evento, e)}>
                        {e.trim()}
                      </button>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          <CopyrightSite />
        </div>
      ) : (
        <div className="seccion--white__container">
          <div className="menu--movile__container">
            <LogoSite />
            <MenuBurguer
              close={close}
              setClose={setClose}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
            />
          </div>
          <div className="seccion--search">
            <div>
              <label form="site-search">Buscar en tienda:</label>
              <input
                onChange={(e) => setConsulta(e.target.value)}
                type="search"
                id="site-search"
                name="q"
              />
              <button onClick={(e) => searchCategory(e)}>Search</button>
            </div>
            <nav>
              <ul>
                <li>
                  <a onClick={() => clickCategory("all")}>
                    Todas las categorias
                  </a>
                </li>
                {category &&
                  category.map((e, i) => (
                    <li key={i}>
                      <a onClick={() => clickCategory(e)}>{e.trim()}</a>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          {openMenu && (
            <div className={`seccion--search__movile ${close ? close : ""}`}>
              <div className="seccion--search__search">
                <input
                  onChange={(e) => setConsulta(e.target.value)}
                  type="search"
                  id="site-search"
                  name="q"
                />
                <button onClick={(e) => searchCategory(e)}>Buscar</button>
              </div>
              <nav className="seccion--search__category">
                <ul className="seccion--search__category--list">
                  <li className="seccion--search__category--item">
                    <button onClick={(evento) => clickCategory(evento, "all")}>
                      Todas las categorias
                    </button>
                  </li>
                  {category &&
                    category.map((e, i) => (
                      <li className="seccion--search__category--item" key={i}>
                        <button onClick={(evento) => clickCategory(evento, e)}>
                          {e.trim()}
                        </button>
                      </li>
                    ))}
                </ul>
              </nav>
            </div>
          )}
          <CopyrightSite />
        </div>
      )}
    </div>
  );
};

export default SectionWhiteTwo;
