import React, { useState } from "react";
import iconAddProduct from "../../img/icons/icon-add-product.png";
import iconTouch from "../../img/icons/icon-touch.png";

const FormQuery = ({ getOrdersRaw }) => {
  const [orderReq, setOrderReq] = useState();
  return (
    <div className="form--query">
      <form className="form--query__form">
        <label htmlFor="numeroPedido">Numero de pedido</label>
        <input
        className="orderID"
          onChange={(e) => setOrderReq(e.target.value)}
          placeholder="N° de pedido"
          type="text"
          id="numeroPedido"
        />
        <button onClick={(e) => getOrdersRaw(e,orderReq)} type="submit">
          Consultar
          <img src={iconTouch} alt="icon login" />
        </button>
      </form>
    </div>
  );
};

export default FormQuery;
