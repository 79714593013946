import React, { useState } from "react";
import btnBurguer from "../../img/icons/icon-menu-btn.png";
import btnBurguerClose from "../../img/icons/icon-menu-btn-close.png";

const MenuBurguer = ({close, setClose, openMenu, setOpenMenu }) => {
  const handleClose = (e) => {
    setClose("seccion--search__movile--close");
    setTimeout(() => {
      setOpenMenu(false);
    }, 1000);
  };
  const handleOpen = () => {
    setClose("");
    setOpenMenu(true);
  };
  return (
    <div className="menu--movile">
      {openMenu ? (
        <img
          className= {close ?"close--menu__movile--out" :"close--menu__movile"}
          onClick={(e) => handleClose(e)}
          src={btnBurguerClose}
          alt="btnBurguerClose"
        />
      ) : (
        <img
          className="open--menu__movile"
          onClick={() => handleOpen()}
          src={btnBurguer}
          alt="btnBurguer"
        />
      )}
    </div>
  );
};

export default MenuBurguer;
