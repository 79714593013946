import React, { useEffect, useState } from 'react'
import getRemitente from '../../helpers/getUsersRemitente';
import Spinner from './Spinner';

const PopupC = ({ popupView, setPopupView }) => {
    const [popupContent, setPopupContent] = useState(null);
    const [imgContent, setImgContent] = useState("");
    const url = process.env.REACT_APP_URL_API + "popup";
    const urlFile = process.env.REACT_APP_URL_API + "files/";

    useEffect(() => {
        const obtenerInfoPopup = async () => {
            const { popupAll } = await getRemitente(url, "GET");
            console.log(popupAll);
            setPopupContent(popupAll[0])
            console.log(popupAll[0]);
            setImgContent(urlFile + popupAll[0].imagen);
        };
        obtenerInfoPopup();
    }, []);

    const handleClick = (e) => {
        e.preventDefault()
        setPopupView(false)
    }
    return (
        <>
            {popupContent ?
                <div>
                    {popupContent.alter == 'on' &&
                        <div className="popup">
                            <div className="popup__container">
                                <button onClick={(e) => handleClick(e)} className="popup__close">X</button>
                                <h3 className="popup__title">{popupContent.nombre}</h3>
                                <img className="popup__image" src={imgContent} alt="oferta" />
                                <p className="popup__descripcion">{popupContent.descripcion}</p>
                            </div>
                        </div>
                    }
                </div>

                : <Spinner />}
        </>
    )
}

export default PopupC