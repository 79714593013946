/*global fbq*/
import React, { useEffect, useState, useContext } from "react";
import iconTouch from "../../img/icons/icon-touch.png";
import colombia from "../../db/Colombia.json";
import swal from "sweetalert";
import createOrder from "../../helpers/createOrder";
import Back from "../atoms/Back";
import { Dcontex } from "../../App";
import { Link } from "react-router-dom";


const FormPersonalData = ({
  data,
  setdata,
  setPlaceOrder,
  setPersonalData,
  personalData,
  setOrderSend,
  setPreOrder,
  preorder,
  placeOrder,
}) => {
  // const [data, setdata] = useState({});
  const value = useContext(Dcontex)
  let ciudades = [];
  const [selectCiudades, setSelectCiudades] = useState();
  const url = process.env.REACT_APP_URL_API + "pedido";

  const departamento = (event) => {
    setdata({ ...data, departamento: event.target.value });
    ciudades = colombia.filter((e) => e.departamento === event.target.value);
    ciudades && setSelectCiudades(ciudades[0].ciudades);
  };

  const handleSubmmit = (e) => {
    e.preventDefault();
    if (!data.politicas) {
      swal("Debes aceptar las politcas de privacidad")
      return
    }
    if (
      !data.departamento ||
      !data.ciudad ||
      !data.nombre ||
      !data.cedula ||
      !data.telefono ||
      !data.email ||
      !data.direccion
    ) {
      swal("Todos los campos son obigatorios");
      return;
    } else {
      setPersonalData(data);
      setPreOrder({ ...data, total: preorder.total, productospedido: preorder.productospedido, totalinterno: preorder.totalInterno })
      fbq('trackCustom', 'Finalizar pedido', {
        user_region:data.departamento,
        user_city:data.ciudad,
        user_name:data.nombre,
        user_id:data.cedula,
        user_phone:data.telefono,
        user_email:data.email,
        user_adress:data.direccion,
        country_name: value.country_name,
        country_capital: value.country_capital,
        city: value.city,
        region: value.region,
        timezone: value.timezone,
        postal: value.postal,
        currency: value.currency,
        ip: value.ip,
        order: preorder
      });
      console.log('enviando pixel de Finalizar pedido');
    }
  };

  useEffect(() => {
    if (preorder.nombre !== ' ' && preorder.nombre !== undefined) {
      pedidoCReate(preorder)
    }
  }, [preorder])
  let pedidoCReate = async (preorder) => {
    let orderSend = await createOrder(url, "POST", preorder);
    setOrderSend({ pedido: orderSend.pedido, total: preorder.total, totalinterno: preorder.totalinterno });
    setPlaceOrder("confirmacion");
  };
  return (
    <div className="form--personal">
      <div className="form--personal__container">
        <form onSubmit={(e) => handleSubmmit(e)}>
          <Back placeOrder={placeOrder} setPlaceOrder={setPlaceOrder} />
          <label htmlFor="nombreCliente">Nombre</label>
          <input
            onChange={(e) => setdata({ ...data, nombre: e.target.value })}
            type="text"
            id="nombreCliente"
            defaultValue={data.nombre}
          />

          <label htmlFor="cedulaCliente">Cédula</label>
          <input
            onChange={(e) => setdata({ ...data, cedula: e.target.value })}
            type="number"
            id="cedulaCliente"
            defaultValue={data.cedula}
          />

          <label htmlFor="teléfonoCliente">Teléfono</label>
          <input
            onChange={(e) => setdata({ ...data, telefono: e.target.value })}
            type="number"
            id="teléfonoCliente"
            defaultValue={data.telefono}
          />

          <label htmlFor="emailCliente">Correo electrónico</label>
          <input
            onChange={(e) => setdata({ ...data, email: e.target.value })}
            type="email"
            id="emailCliente"
            defaultValue={data.email}
          />

          <label htmlFor="direccionCliente">Dirección</label>
          <input
            onChange={(e) => setdata({ ...data, direccion: e.target.value })}
            type="text"
            id="direccionCliente"
            defaultValue={data.direccion}
          />

          <label htmlFor="departamento">Departamento</label>
          <select
            onChange={(e) => departamento(e)}
            name="departamento"
            id="departamento"
          >
            <option defaultValue={data.departamento ? data.departamento : ''}>
              {data.departamento ? data.departamento : 'Departamento'}
            </option>
            {colombia.map((e, i) => (
              <option value={e.departamento} key={i}>
                {e.departamento}
              </option>
            ))}
          </select>

          <label htmlFor="ciudad">Ciudad</label>
          <select
            onChange={(e) => setdata({ ...data, ciudad: e.target.value })}
            name="ciudad"
            id="ciudad"
          >
            <option defaultValue={data.ciudad ? data.ciudad : ''}> {data.ciudad ? data.ciudad : 'Selecciona un Departamento'}</option>
            {selectCiudades &&
              selectCiudades.map((e, i) => (
                <option value={e} key={i}>
                  {e}
                </option>
              ))}
          </select>
          <div style={{ display: 'flex', width: '25.5rem', gap: '1rem', marginTop: '1.5rem', alignItems: 'flex-start'}}>
          <input
          style={{ width: '2rem'}}
            onChange={(e) => setdata({ ...data, politicas: e.target.checked })}
            type="checkbox"
            id="politicasPrivacidad"
            defaultValue={data.direccion}
          />
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <label htmlFor="politicasPrivacidad">Politicas de privacidad</label>  
            <Link target="_blank" style={{textDecoration: 'none'}} to={'/politicas'} >
             <p style={{margin: 0, color: 'white', fontSize: '1.2rem', color: '#5564eb'}}>Ver Politicas</p>
            </Link>
          </div>
          </div>
          <button type="submit">
            Hacer pedido
            <img src={iconTouch} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormPersonalData;
