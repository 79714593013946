import swal from "sweetalert";

const getUsers = async (url,method, token) => {
  const req = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
    },
  });
  if (req.status === 200) {
    const res = await req.json();
    return res;
  } else {
    swal("algo ha salido mal en la peticion, contacte con el soporte técnico");
  }
};
export default getUsers;