import React, { useEffect, useState } from "react";
import getProducts from "../../helpers/getProducts";
import CardProduct from "../atoms/CardProduct";
import Spinner from "../atoms/Spinner";

import iconCarShopWithe from "../../img/icons/icon-car-white.png";
import iconTouchWhite from "../../img/icons/icon-touch-white.png";
import iconPDFBlack from "../../img/icons/icon-pdf-black.png";

const ProductosEdit = ({
  sendPreOrder,
  setProductsCar,
  productsCar,
  setTotal,
  total,
  setCategory,
  personalData,
  filterCategory,
}) => {

  const url = process.env.REACT_APP_URL_API + 'producto';

  const [products, setProducts] = useState("");
  const [cantidad, setCantidad] = useState([]);
  const [productsTrue, setProductsTrue] = useState("");

  useEffect(() => {
    async function stockTienda() {
      let produtosAll = await getProducts(url);
      setProductsTrue(produtosAll.productoAll);
      setProducts(produtosAll.productoAll);

      let categoryRaw = produtosAll.productoAll.map((e) =>
        e.categorias.split(",")
      );
      let categotyPlain = categoryRaw.flat(2);
      let categotyTrim = categotyPlain.map((e) => e.trim());
      let categotyClean = new Set(categotyTrim);
      setCategory([...categotyClean]);
    }
    stockTienda();
  }, []);

  useEffect(() => {
    if (filterCategory) {
      if (filterCategory === "all") {
        setProductsTrue(products);
        return;
      }
      const productsFilter = products.map((e) => {
        let categoriasSplit = e.categorias.split(",");
        let categoriasValidate = categoriasSplit.filter((cat) =>
          cat.trim().includes(filterCategory)
        );
        if (categoriasValidate.length > 0) {
          return e;
        } 
      });
      setProductsTrue(productsFilter.filter((e) => e !== undefined));
    }else {
      setProductsTrue(products);
    }
  }, [filterCategory]);

  useEffect(() => {
    getTotal();
  }, [productsCar]);

  const getTotal = () => {
    const totalScore = productsCar.map((e) => e.precio);
    setTotal(
      totalScore.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
    const totalCantidad = productsCar.map((e) => Number(e.cantidad));
    setCantidad(
      totalCantidad.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  };

  return (
    <div className="productos--page">
      <header className="productos--page__header">
        <div className="carrito">
          <div className="carrito--info">
            <img src={iconCarShopWithe} alt="icon tenis Cúcuta" />
            <div>
              <p>
                Cantidad: <span>{cantidad}</span>
              </p>
              <p>
                Total: <span>${total}</span>{" "}
              </p>
            </div>
          </div>
          <button onClick={() => sendPreOrder()} className="carrito--pedido">
            <p>Agregar</p> <img src={iconTouchWhite} alt="" />
          </button>
        </div>
      </header>
      <div className="productos--page__tienda tienda--productos">
        {productsTrue ? (
          productsTrue.map((element, i) => (
            <CardProduct
              productsCar={productsCar}
              setProductsCar={setProductsCar}
              key={i}
              element={element}
            />
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default ProductosEdit;
