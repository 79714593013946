import React from "react";
import { Link } from "react-router-dom";
import SectionWhite from "../molecules/SectionWhite";
import iconVerificarPedido from "../../img/icons/icon-verificar-pedido.png";
import iconhacerPedido from "../../img/icons/icon-hacer-pedido.png";

const PageHome = () => {

  return (
    <div className="page--base page--home">
      <div className="page--base__container  page--home__container">
        <div className="page--base__container--left">
          <SectionWhite />
        </div>
        <div className="page--base__container--right ">
          <div className="user--verificar">
            <img src={iconVerificarPedido} alt="verificar pedido" />
            <Link to="/order">
              <button>Consultar pedido</button>
            </Link>
          </div>
          <div className="user--order">
            <img src={iconhacerPedido} alt="verificar pedido" />
            <Link to="/tienda">
              <button> Realizar pedido</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHome;
