/*global fbq*/
import React, { useState, useEffect, useContext } from "react";
import nextId from "react-id-generator";
import swal from "sweetalert";
import iconCarShop from "../../img/icons/icon-car.png";
import iconPlayVideo from "../../img/icons/icon-play-video.png";
import { Dcontex } from "../../App";


const CardProduct = ({ element, productsCar, setProductsCar }) => {
  const imageRute = process.env.REACT_APP_URL_API + 'files/';
  const [tallas, setTallas] = useState([]);
  const [cantidadInput, setCantidadInput] = useState([]);
  const [agotado, setAgotado] = useState(false);
  const [carrito, setCarrito] = useState("");
  const { imagen, nombre, precio, preciointerno, stock, video, id } = element;
  const [tallaCarrito, setTallaCarrito] = useState("");
  const [cantidadCarrito, setCantidadCarrito] = useState("");
  // const [preciointerno, setPreciointerno] = useState("");
  const value = useContext(Dcontex)

  const orderUID = nextId();


  useEffect(() => {
    function cardproduct(params) {
      setTallas(stock.split(","));
      !stock && setAgotado(true);
      // stock && stock.split(',').map(e => {
      //  if (Number(e.split(':')[1]) === 0) {
      //   setAgotado(true);
      //  } 
      // })
    }
    cardproduct();
  }, [stock]);

  useEffect(() => {
    carrito && setProductsCar([...productsCar, carrito]);
  }, [carrito]);

  const handleClick = (e) => {
    e.preventDefault()
    if (
      tallaCarrito === "" ||
      cantidadCarrito == "" ||
      cantidadCarrito > cantidadInput
    ) {
      swal("debes elegir una talla y una cantidad valida");
      return;
    }
    setCarrito({
      talla: tallaCarrito,
      nombre: nombre,
      imagen: imageRute + imagen,
      id: id,
      cantidad: cantidadCarrito,
      precio: cantidadCarrito * precio,
      preciointerno: cantidadCarrito * preciointerno,
      uid: orderUID
    });
    fbq('trackCustom', 'Agregar al carrito', {
      producto_talla: tallaCarrito,
      producto_nombre: nombre,
      producto_id: id,
      producto_cantidad: cantidadCarrito,
      precio: cantidadCarrito * precio,
      country_name: value.country_name,
      country_capital: value.country_capital,
      city: value.city,
      region: value.region,
      timezone: value.timezone,
      postal: value.postal,
      currency: value.currency,
      ip: value.ip,
    });
    console.log('enviando pixel de agregar carrito');
    setTallaCarrito("");
    setCantidadCarrito("");
  };

  const handleTallas = (e) => {
    if (e.target.value === "tallas") {
      swal("debes elegir una talla y cantidad valida");
      return;
    }
    setTallaCarrito(e.target.value);
    let cantidadTallas = tallas.filter(
      (element) => element.split(":")[0] === e.target.value
    );
    let cantidadArray = cantidadTallas.map((e) => e.split(":"));
    setCantidadInput(Number(cantidadArray[0][1]));
  };
  // e.target.value !== ""
  const handleCantidad = (e) => {
    if (e.target.value <= cantidadInput) {
      setCantidadCarrito(e.target.value);
    } else {
      swal("no hay suficientes productos para esta talla");
      return;
    }
  };

  return (
    <div
      className={agotado ? `card--product producto--agotado` : `card--product`}
    >
      <div className="card--product__left">
        <img
          className="image--product"
          src={`${imageRute}${imagen}`}
          alt="producto tenis Cúcuta"
        />
        <a href={video} target="_blank">
          <img
            className="video--product"
            src={iconPlayVideo}
            alt="producto tenis Cúcuta"
          />
        </a>
      </div>
      <div className="card--product__right">
        <div className="card--product__right--info">
          <h2>{nombre}</h2>
          <h2>{`Precio: $${precio}`}</h2>
          <form className="form">
            <select
              value={tallaCarrito}
              onChange={(e) => handleTallas(e)}
              className="form--tallas"
            >
              <option value="tallas">Tallas</option>
              {tallas &&
                tallas.map((e, i) => (
                  <option value={e.split(":")[0]} key={i}>
                    {e.split(":")[0]}
                  </option>
                ))}
            </select>
            <input
              onChange={(e) => handleCantidad(e)}
              min={0}
              max={cantidadInput}
              placeholder="cantidad"
              type="number"
              value={cantidadCarrito}
            ></input>
            <button onClick={(e) => handleClick(e)}>
              <p>Añadir al carrito</p> <img src={iconCarShop} alt="" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
