import React, { useContext, useEffect, useState } from "react";
import estado1 from "../../img/icons/receipt_success_icon.png";
import estado3 from "../../img/icons/money_success_icon_.png";
import estado4 from "../../img/icons/package_success_icon.png";
import estado5 from "../../img/icons/shipping_success_icon.png";
import estado2 from "../../img/icons/peridoAgendado.png";
import Spinner from "./Spinner";

const OrderTimeLine = ({ consulta }) => {
  const [openSpinner, setOpenSpinner] = useState();
  const interrapidisimoURL = ' http://inter.la/s/'
  const urlGuia = `${process.env.REACT_APP_URL_API}guias/`;
  useEffect(() => {
    if (consulta) {
      setOpenSpinner(true);
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    }
  }, [consulta]);

  const clases = { clase1: "", clase2: "", clase3: "", clase4: "", clase5: "" };
  if (consulta) {
    switch (consulta.estado) {
      case "Pedido recibido":
        clases.clase1 = "activado";
        break;
      case 'Pedido Agendado':
        clases.clase1 = "activado";
        clases.clase2 = "activado";
        break;
      case "Pago aprobado":
        clases.clase1 = "activado";
        clases.clase2 = "activado";
        clases.clase3 = "activado";
        break;
      case "Preparando pedido":
        clases.clase1 = "activado";
        clases.clase2 = "activado";
        clases.clase3 = "activado";
        clases.clase4 = "activado";
        break;
      case "Pedido enviado":
        clases.clase1 = "activado";
        clases.clase2 = "activado";
        clases.clase3 = "activado";
        clases.clase4 = "activado";
        clases.clase5 = "activado";
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className="order--time__line">
        <div className="order--time__line--items">
          <img className={clases.clase1} src={estado1} alt="Pedido recibido" />
          <hr className={clases.clase2} />
          <img  className={clases.clase2 + ' pedido__agendado'} src={estado2} alt="Pedido Agendado" />
          <hr className={clases.clase3} />
          <img className={clases.clase3} src={estado3} alt="pago aprobado" />
          <hr className={clases.clase4} />
          <img
            className={clases.clase4}
            src={estado4}
            alt="preparando pedido"
          />
          <hr className={clases.clase5} />
          <img className={clases.clase5} src={estado5} alt="pedido enviado" />
        </div>
        <div className="order--time__line--msg">
          {openSpinner ? (
            <Spinner />
          ) : (
            <div>
              <p>N° Pedido </p>
              <h2>{consulta && consulta.id}</h2>
              <h2>{consulta && consulta.error}</h2>
              <p>Estado de pedido</p>
              <h2>
                {consulta && consulta.estado}
              </h2>
              {consulta && (
                <>
                  {consulta.estado === "Pedido enviado" ? (
                    <>
                      <div >
                        <p>Empresa</p>
                        <h2>{consulta && consulta.empresa}</h2>
                      </div>
                      <div className="guia--info">
                        <p>N° Guia</p>
                        <h2>{consulta && consulta.guia}</h2>
                        {consulta.empresa === 'Interrapidísimo' ? <a href={`${interrapidisimoURL}${consulta.guia}`} target='_blank'>
                          <button>Ver</button>
                        </a> : null}
                      </div>
                      {consulta.imagen
                        ?
                        <div className="guia--imagen">
                          <img src={urlGuia + consulta.imagen} />
                          <a target='_blank' href={urlGuia + consulta.imagen} download>
                            Descargar
                          </a>
                        </div>
                        : null
                      }
                    </>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderTimeLine;
