import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import createProductApi from "../../helpers/createProductApi";
import getRemitente from "../../helpers/getUsersRemitente";
import iconLogin from "../../img/icons/icon-login-admin.png";
import imgPlaceholder from "../../img/icons/product-placeholder.png";

const Popup = ({ token }) => {
  const url = process.env.REACT_APP_URL_API + "popup";
  const urlid = process.env.REACT_APP_URL_API + "popup/1";
  const urlFile = process.env.REACT_APP_URL_API + "files/";
  const [newpopupContent, setNetPopupContent] = useState(null);
  const [popupContent, setPopupContent] = useState(null);
  const [imgContent, setImgContent] = useState("");
  const [imgUpload, setImgUpload] = useState("");

  useEffect(() => {
    const obtenerInfoPopup = async () => {
      const { popupAll } = await getRemitente(url, "GET", token);
      setPopupContent(popupAll[0])
      setImgContent(urlFile + popupAll[0].imagen);
    };
    obtenerInfoPopup();
  }, []);

  const subirArchivos = (e) => {
    setImgContent(URL.createObjectURL(e[0]));
    setImgUpload(e);
  };


  const actualizarPopup = async (e) => {
    e.preventDefault();
    console.log(e.target[0].value === '')
    console.log(e.target[1].value === '')
    let filesData = new FormData();
    if (e.target[0].value === '' || e.target[1].value === '' || e.target[2].value === '') {
      swal('todos los campos son obligatorios')
      return
    }else {
      filesData.append('nombre', e.target[0].value )
      filesData.append('descripcion', e.target[2].value )
      filesData.append('alter', e.target[1].value )
    }
    imgUpload
      ? filesData.append("image", imgUpload[0])
      : filesData.append("image", popupContent.imagen);
    const result = await createProductApi(urlid, "PUT", filesData, token);
    console.log(result);
  };
  return (
    <div className="form--remitente">
      <div className="form--remitente__container">
        <form onSubmit={(e) => actualizarPopup(e)}>
          <label htmlFor="nombreRemitente">Nombre Campaña</label>
          <input
            onChange={(e) =>
              setNetPopupContent({ ...popupContent, nombre: e.target.value })
            }
            defaultValue={popupContent && popupContent.nombre}
            type="text"
            id="nombrepopupContent"
          />
          <label htmlFor="nombreRemitente">Estado 'on | off'</label>
          <input
            onChange={(e) =>
              setNetPopupContent({ ...popupContent, alter: e.target.value })
            }
            defaultValue={popupContent && popupContent.alter}
            type="text"
            id="nombrepopupContent"
          />
          <label htmlFor="emailpopupContent">Descripcion Campaña</label>
          <input
            onChange={(e) =>
              setNetPopupContent({ ...popupContent, descripcion: e.target.value })
            }
            defaultValue={popupContent && popupContent.descripcion}
            type="text"
            id="emailpopupContent"
          />
          <label htmlFor="cedulapopupContent">Imagen Campaña</label>
          <img style={{ width: '250px', height: '250px', objectFit: 'cover' }} src={imgContent ? imgContent : popupContent && popupContent.imagen} alt='imagen' />

          <input
            accept=".jpg"
            onChange={(e) => subirArchivos(e.target.files)}
            name="image"
            type="file"
            id="imagenProducto"
          />

          <button type="submit">
            Actualizar popupContent
            <img src={iconLogin} alt="icon login" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
