import swal from "sweetalert";

const createOrder = async (url, method, body, token ) => {
    const req = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token && token
        },
        body: JSON.stringify(body),
    });
    if (req) {
      const res = await req.json();
      return res
    } else {
      swal("UPPS! algo ha salido mal.", "verifica tus credenciales");
    }
  };

export default createOrder